.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-right: none !important;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.faq-img > img {
  z-index: 1;
  width: 150px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.faq-img-title {
  font-weight: 700;
  font-size: 18px;
}

.faq-card {
  transition: 0.5s all;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.06) !important;
  cursor: pointer;
  border: 1px solid grey;
}

.faq-card:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.faq-active {
  transition: 15s all;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.faq-fadeIn {
  animation: opac 0.8s;
}

.faq-serach-input {
  border-top-left-radius: 78px !important;
  border-bottom-left-radius: 78px !important;
  border: 1px solid #e0e0e0;
}

.faq-serach-input:focus,
.faq-serach-input:active {
  outline: none !important;
  box-shadow: none !important;
}

.faq-search-button {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
  border-top-right-radius: 78px !important;
  border-bottom-right-radius: 78px !important;
}

.faq-card-heading {
  font-size: 22px;
  line-height: 48px;
  font-weight: 600;
}

.faq-card-desc {
  font-size: 14px;
  line-height: 28px;
}
.faq-extand-title{
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 992px){
  .faq-card {
    padding: 0px 60px;
  }
}