@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  margin: 0px;
}
.over-x-unset {
  overflow-x: unset;
}
.navbar-container {
  max-width: 1140px;
  margin: 0px auto 0px auto;
  /* padding: 15px !important; */
}
.navbar-container-1 {
  max-width: 1140px;
  margin: 0px auto 0px auto;
  /* padding: 15px 30px !important; */
}
.navbar-light.header-scroll-takeover {
  background: #fbfbfb !important;
}
.navbar {
  padding: 15px;
}
.navbar-1 {
  padding: 15px 30px !important;
}
.navbar-dark.header-scroll-takeover {
  background: #000 !important;
}
body {
  background-color: #fbfbfb;
  /* background-color: #FFFFFF; */
}
.form-control {
  color: #495057;
  background-color: #fbfbfb !important;
  height: 60px;
}
.text-000 {
  color: #000;
}
.w-75 {
  width: 75% !important;
}
.w-52 {
  width: 52% !important;
}
.w-60 {
  width: 60% !important;
}
.fz-48 {
  font-size: 48px !important;
}
.fz-18 {
  font-size: 18px !important;
}
.pb-28 {
  padding-bottom: 28px;
}
.mb-10 {
  margin-bottom: 46px !important;
}
.mx-w-47 {
  max-width: 47%;
}
.mx-w-60 {
  max-width: 60%;
}
.mx-w-90 {
  max-width: 99% !important;
}
.lh-40 {
  line-height: 40px !important;
}
.w-108 {
  width: 108% !important;
}
.w-121 {
  width: 121%;
}
.w-147 {
  width: 147% !important;
}
.icon-shadow {
  box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.02);
}
.navbar-light .navbar-nav .nav-link.active {
  font-weight: 600;
}
.ml-75 {
  margin-left: 75px;
}
.footer-container .text-make {
  margin-left: -94px;
}
.home-nav-signin-btn {
  font-weight: 700 !important;
  font-size: 12px;
}

.home-nav-signup-btn {
  font-weight: 700 !important;
  font-size: 12px;
  border-radius: 4em;
  border: 2px solid #000;
}

.cheader-nav-signup-btn {
  font-weight: 700 !important;
  font-size: 12px;
  border-radius: 4em;
  border: 2px solid #fff;
}

.home-header-btn {
  border: 1px solid #000;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 50px;
}

.home-header-sub-btn {
  width: 90%;
}

.footer-making-text {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.footer-unique-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.65;
}

.footer-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #8c8b8b;
  opacity: 0.3;
  width: 83%;
}

.footer-instacut-text {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.16px;
  font-weight: 700;
  color: #ffffff;
}

.footer-home-text,
.footer-Stylish-text,
.footer-Safety-text,
.footer-Company-text {
  color: #ffffff;
  font-size: 15px;
  line-height: 26px;
  opacity: 0.65;
}

.footer-instacut-desc {
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.65;
  letter-spacing: 0.5px;
}

.footer-text {
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #ffffff;
}

.footer-box-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 0.5px solid #8c8b8b;
  opacity: 0.1;
  width: 80%;
}

.nav-main {
  background-color: transparent;
}

.nav-logo {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

.nav-sub {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

/* .navbar-dark.left-384{
  left: 384px;
} */

.navbar-light .navbar-brand {
  color: #000;
  font-size: 24px;
}

.footer-container {
  background-image: url(./components/footer_bg.png);
  z-index: -1;
}

.footer-social-icons > span {
  color: #595959 !important;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > a {
  color: #595959;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > a:hover {
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > span:hover {
  color: rgb(250, 250, 250);
  transition: 0.3s;
}

.footer-making-text {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.footer-unique-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.65 !important;
  margin-top: 20px;
}

.footer-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #8c8b8b;
  opacity: 0.3;
  width: 89%;
}

.footer-instacut-text {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.16px;
  font-weight: 700;
  color: #ffffff;
}

.footer-home-text,
.footer-Stylish-text,
.footer-Safety-text,
.footer-Company-text {
  color: #ffffff;
  font-size: 15px;
  line-height: 26px;
  opacity: 0.65 !important;
}

.footer-instacut-desc {
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.65 !important;
  letter-spacing: 0.5px;
}

.footer-text {
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #ffffff;
}

/* .footer-text:hover {
  border-bottom:1px solid white;
} */
a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer-box-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 0.5px solid #8c8b8b;
  opacity: 0.1;
  width: 80%;
}

.footer-image {
  width: 100%;
}

.footer-unique-text {
  width: 85%;
}

/* .home-apps-imgs {
  width: 35% !important;
} */

.home-apps-imgs {
  width: auto;
}

.footer-links > a {
  text-decoration: none;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

/* media query */
@media only screen and (min-width: 1921px) {
  .footer-underline {
    width: 43% !important;
  }

  .home-dot-people-img2 {
    margin-top: 140px !important;
    left: 602px !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1920px) {
  .footer-underline {
    width: 57% !important;
  }
  .home-dot-people-img2 {
    margin-top: 7% !important;
    left: 15% !important;
  }
}
@media only screen and (min-width: 1601px) {
  /* .about-us-right-image {
    left: 219px !important;
    top: 59px !important;
  } */
  /* .home-dot-people-img2 {
    margin-top: 5% !important;
    left: 23% !important;
  } */
  .home-dot-people-img {
    margin-top: 7% !important;
    left: 0px !important;
  }
}

@media only screen and (max-width: 1439px) {
  .margin-0px-under-1439 {
    margin: 0px !important;
  }
  .customer-safety-bottom-space-div {
    padding-bottom: 50px !important;
  }
  .why-join-us-image {
    width: 100% !important;
    height: auto !important;
  }
  .customer-testimonials-top-space {
    margin-top: 50px !important;
  }
  .get-groomed-top-space {
    margin-top: 50px !important;
  }

  .container-for-image-card {
    margin: 50px auto 0px auto !important;
  }

  .if-your-padding {
    padding: 20px !important;
  }
  .people-container {
    padding: 20px;
  }
}

@media only screen and (min-width: 1601px) {
  .mb-10 {
    margin-bottom: 0% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) {
  .footer-underline {
    width: 76% !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .home-dot-people-img-2 {
    right: 0px !important;
  }
  .home-dot-people-img {
    margin-top: 12% !important;
    left: 0px !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .home-dot-people-img2 {
    left: 47px !important;
    margin-top: 140px;
  }
}
@media only screen and (min-width: 1024px) {
  .padding-for-home-page-button {
    padding: 0px 100px 0px 7px;
  }
  .display-none-on-big-screen {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .mb-10 {
    margin-bottom: 100px !important;
  }

  .faq-card-row-top-space {
    margin-top: 55px;
    padding: 20px;
  }
  .faq-search-bar-height {
    width: 98%;
  }
  .slider-post-text {
    padding: 0px 0px 20px 0px !important;
  }
  .COVID-19-Door-to-Door-Safety-content-padding {
    padding: 0px 20px 50px 20px !important;
  }
  .overflow-xunset-under-1023 {
    overflow-x: unset;
  }
  /* .header-container {
    padding: 15px 15px !important;
  } */
  .counting-border-bottom-padding {
    padding: 50px 0px 50px 50px !important;
  }
  .customer-safety-image {
    width: 100% !important;
  }
  .orders-left-space {
    margin-left: 5px !important;
  }
  .my-display-hidden {
    display: none;
  }
  .about-any-desc-text {
    font-size: 22px !important;
    line-height: 42px !important;
  }
  .dont-be-stranger-padding {
    padding: 27px 15px 49px 20px !important;
  }

  .with-lots-of-unique-padding {
    padding: 25px 10px 51px 10px !important;
  }
  .about-box-work {
    padding: 50px 30px 50px 30px !important;
  }

  .about-us-top-image {
    width: 100% !important;
    height: auto !important;
  }
  .about-us-lorm-ispum-padding {
    padding: 14px 10px 20px 10px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-top: 20px !important;
  }

  .stylist-with-carry-third-image {
    width: 100% !important;
  }

  .safety-desc-text {
    padding-right: 0px !important;
  }
  .safety-containerr-inner-padding {
    padding: 20px !important;
    margin: 0px;
  }
  .stylis-last-btn-align-right {
    margin: 50px 0px 50px auto !important;
  }
  .stylist-faq-lorem-text-padding {
    padding: 20px 20px 20px 20px !important;
  }
  .stylist-love-slider-width {
    width: 100% !important;
    height: auto !important;
    margin: 0px !important;
  }
  .padding-for-text-below-milestone {
    padding: 0px 0px 0px 0px !important;
  }
  .miled-stone-top-space {
    margin-top: 50px !important;
    padding: 0px 20px;
  }

  .right-10px-under-768 {
    right: 10px !important;
    margin-top: 64px !important;
  }
  .join-points {
    margin-right: 0px !important;
    margin-top: 24px;
  }

  .width-100-under-768 {
    width: 100% !important;
  }
  .home-work-desc {
    width: 100%;
  }
  .feature {
    width: 100%;
  }
}

.safety .home-work-desc.These-are-the {
  padding: 15px 0 15px 0;
}

@media only screen and (max-width: 767px) {
  .container-for-image-card {
    margin: 100px auto 0px auto !important;
  }
  .padding-for-footer-on-mobile {
    padding: 0px 20px !important;
  }
  .padding-zer-for-footer-on-mobile {
    padding: 0px !important;
  }
  .chhose-book-and-get-container-faq {
    margin: 50px auto 0px auto !important;
  }
  .faq-extand-title {
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .safety-top-sapce {
    margin-top: 20px !important;
  }
  .home-work-desc {
    font-size: 16px !important;
  }
  .home-header-sub-btn {
    width: 100% !important;
  }
  .margin-top-60px-for-row-stylist {
    margin-top: 0px !important;
  }
  .stylist-page-number-top-space {
    margin-top: 50px;
  }
  .take-on-new-top-space {
    margin-top: 0px !important;
    padding: 20px;
  }
  .customer-way-text {
    font-size: 18px !important;
  }
  .signup-crreate-account {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .signup-desc {
    padding: 10px;
  }
  .about-text-1 {
    font-size: 18px !important;
  }

  .about-work-text {
    font-size: 32px !important;
  }
  .about-font {
    font-size: 32px !important;
  }
  .contact-create-account {
    font-size: 32px !important;
  }
  .font-size-32px-under-767 {
    font-size: 32px !important;
  }
  .safety-image1-heading {
    font-size: 18px !important;
  }
  .home-choose-headings {
    font-size: 18px !important;
  }
  .margin-top-130px {
    margin-top: 130px !important;
  }
  .home-work-title2 {
    font-size: 32px !important;
  }
  .slider-icons-prev-next {
    width: 40px !important;
    height: 40px !important;
  }
  .home-book-your-Button {
    margin: 0px auto !important;
  }
  /* .home-header-btn {
    margin: 15px auto !important;
  } */
  .chhose-book-and-get-container {
    margin: 100px auto 0px auto !important;
    width: 98%;
    /* text-align: center; */
  }
  .padding-0px-under-767 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .margin-top20px-under-767px {
    margin-top: 20px;
  }
  .padding-horizantly-10px-767px {
    padding: 0px 10px;
  }

  .home-work-title {
    font-size: 32px !important;
  }
  .safety-top-sapce {
    padding: 10px;
  }
  .margin-top-100-under-767px {
    margin: 130px auto 50px auto !important;
  }
  .here-what-text-top-psace {
    margin: 20px 0px 50px 0px !important;
  }
  .stylist-cng-subimg-2 {
    top: 59% !important;
    left: 6% !important;
  }

  .hhelping-image-div {
    margin-top: 10px;
  }
  .faq-customer-text-top-space-under-425 {
    margin-top: 50px;
  }
  .frequently-asked-space {
    padding: 20px 0px 50px 0px !important;
  }
  .have-any-que-main-div-space {
    margin: 130px 0px 50px 0px !important;
    padding: 50px 0px;
  }
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .intrsted-to-join-top-space {
    margin-top: 10% !important;
  }
  .dedicated-row-top-space {
    padding: 20px 0px 0px 0px !important;
  }
  .with-lots-of {
    padding: 20px 10px 20px 10px !important;
  }
  .work-around-main-div-top-and-bottom-space {
    margin: 50px 0px 50px 0px;
  }
  .safety-and-constantly-paddinf {
    padding: 0px 0px 0px 0px !important;
  }
  .safety-helping-image {
    right: 1px !important;
  }

  .btn-stylis-width {
    width: 100% !important;
    margin: 0px 0px 0px 20px;
  }
  .stylist-iamge {
    height: auto !important;
    padding-bottom: 50px;
  }
  .chhose-book-and-get-container-text {
    padding: 50px 0px 0px 0px !important;
  }
  /* .chhose-book-and-get-image-container {
    height: auto !important;
  } */
}

@media only screen and (max-width: 374px) {
  .home_work_lines {
    bottom: 30% !important;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .hhelping-image-div {
    height: 270px !important;
  }
  .stylist-with-carry-third-image {
    height: 109px !important;
    margin-top: 110px !important;
  }
  .safety-helping-image {
    width: 81px !important;
    height: 93px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: 297px !important;
    object-fit: cover !important;
  }
  .install-our-app-div {
    margin-top: 30px !important;
  }
  .home-nav-signup-btn {
    font-size: 8px !important;
    margin: 1px 0px 0px 0px !important;
  }
  .burger-menue {
    height: 25px !important;
    margin-top: 10px !important;
  }
  .home-nav-signup-btn {
    font-size: 6px !important;
  }
  .footer-text {
    font-size: 9px !important;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 108px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }
  .home-dot-people-img-2 {
    transform: translateY(-93%) !important;
  }
  .stylist-with-carry-second-image {
    width: 160px !important;
    height: 210px !important;
  }

  .home-count {
    font-size: 30px !important;
  }
  .fz-11-on-320 {
    font-size: 11px !important;
  }
  .stylist-form-chk-desc {
    font-size: 10px;
  }
  .padding-left-for-stylist-checkbox {
    padding-left: 15px;
  }
  .home-dot-img {
    bottom: 0px;
    left: 13%;
  }
  .home-chart-img {
    position: relative;
    left: 137px;
    top: 28px;
  }
  .home-woman {
    top: 45% !important;
    left: 16% !important;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 18% !important;
    width: 24%;
  }
  .home-man {
    right: -3px !important;
    top: 33% !important;
  }
  .padding-7px-under-320 {
    padding: 7px 5px !important;
  }
  .image-height-home-page {
    height: 300px !important;
  }
}
@media only screen and (max-width: 424px) {
  .covid-19-door-top-div-space {
    margin-top: 120px !important;
  }
  .safety-is-our-key-priority-top-space {
    margin-top: 130px !important;
  }
  .safety-protocol-top-space {
    margin-top: 90px !important;
  }
  /* .nav-logo {
      font-size: 18px !important;
    } */
  /* .home-safety-card-title {
      font-size: 28px !important;
      line-height: 40px !important;
    } */
  /* .home-work-title2 {
      font-size: 28px !important;
    } */
  .home-choose-desc {
    font-size: 16px !important;
  }
  /* .home-people-title {
      font-size: 28px !important;
  } */
  .home-dot-people-img-2 {
    right: 0px !important;
    height: 100px !important;
    overflow-y: hidden;
  }

  /* .home-work-title {
      font-size: 32px !important;
    } */

  .building-a-safer-text-padding {
    padding: 0px 20px 0px 20px !important;
  }
  .k50-conatiner-padding {
    padding: 20px !important;
  }
  .Heres-an-right-padding {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .home_work_lines {
    bottom: 37% !important;
  }
  .hhelping-image-div {
    height: 300px !important;
  }
  .stylist-with-carry-third-image {
    height: 114px !important;
    margin-top: 98px !important;
  }
  .safety-helping-image {
    width: 87px !important;
    height: 92px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
    /* object-fit: cover !important; */
  }
  .install-our-app-div {
    margin-top: 38px !important;
  }
  .install-our-app-div {
    height: 110px !important;
  }
  .home-nav-signup-btn {
    font-size: 8px !important;
    margin: 1px 0px 0px 0px !important;
  }
  .burger-menue {
    height: 25px !important;
    margin-top: 11px !important;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 48px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }
  .home-conatiner-mobile-height {
    height: 347px !important;
  }
  .home-man {
    right: -2px !important;
    top: 37% !important;
  }
  .home-cng-subimg {
    top: 2% !important;
    right: 22% !important;
  }
  .home-woman {
    top: 41% !important;
    left: 14% !important;
  }
  .stylist-with-carry-second-image {
    width: 214px !important;
    height: 220px !important;
  }

  .padding-left-for-stylist-checkbox {
    padding-left: 16px;
  }
  .home-dot-img {
    bottom: 12px;
    left: 12%;
  }
  .home-chart-img {
    left: 177px !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .home-dotted-line-bottom {
    bottom: 39% !important;
  }
  .home_work_lines {
    bottom: 39% !important;
  }
  .hhelping-image-div {
    height: 300px !important;
  }
  .stylist-with-carry-third-image {
    height: 137px !important;
    margin-top: 110px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
    /* object-fit: cover !important; */
  }
  .install-our-app-div {
    height: 130px !important;
  }
  .home-nav-signin-btn {
    margin-top: 10px;
  }
  .image-height-home-page {
    height: 398px !important;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 48px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }

  .home-dot-people-img-2 {
    transform: translateY(-85%) !important;
    right: 0px !important;
  }
  .install-our-app-div {
    margin-top: 43px !important;
  }

  .stylist-with-carry-second-image {
    width: 230px !important;
    height: 240px !important;
  }
  .building-a-safer-text-padding {
    padding: 0px 30px 30px 30px !important;
  }
  .padding-left-for-stylist-checkbox {
    padding-left: 16px;
  }

  .home-dot-img {
    bottom: 40px;
    left: 10%;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 25%;
  }
  /* .home-look-title {
    font-size: 36px !important;
  } */
  .home-man {
    top: 29% !important;
    right: 8px !important;
  }
  .home-woman {
    top: 38% !important;
    left: 13% !important;
  }
  .home-chart-img {
    left: 198px !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) {
  .footer-home-text,
  .footer-Stylish-text,
  .footer-Safety-text,
  .footer-Company-text {
    margin-bottom: 20px;
  }

  .padding-left-and-right-zero-for-home-page {
    padding: 0px !important;
  }

  .faq-faq-button-padding {
    padding: 0px 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .padding-for-home-faq {
    padding: 0px 30px 0px 20px !important;
  }
  .home_work_lines {
    bottom: 30%;
  }
  .padding-left-and-right-zero-on-stylist {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .padding-for-home-page-button {
    padding: 0px 0px 0px 10px;
  }
  .navbar-1 {
    padding: 15px 60px !important;
  }
  .navbar-container-1 {
    padding: 15px 60px !important;
  }
  .here-top-space {
    margin-top: 0px !important;
  }
  .modal-content {
    padding: 20px !important;
    width: 50% !important;
  }

  .setisfaction-text-bottom-space {
    margin-bottom: 50px !important;
  }
  .quality-service-image {
    width: 100% !important;
  }
  .joe-bridges-photo {
    width: 100% !important;
    height: 300px !important;
    object-fit: cover;
  }
  .footer-text {
    font-size: 10px;
  }
  .flexibility-heading-top-space {
    margin-top: 20px !important;
  }
  .fz-26px-flexibility {
    font-size: 23px !important;
  }
  .home-safety-card-title {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  /* .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
} */
  .we-are-commited-image {
    width: 100% !important;
    height: 360px !important;
    object-fit: cover !important;
  }
  .padding-top-for-20px-safety-image {
    padding: 20px 0px 0px 0px;
  }
  .building-a-safer-padding {
    padding: 0px 20px 50px 20px !important;
  }
  .we-also-have-a-greate-padding {
    padding: 130px 20px 34px 20px !important;
  }
  .why-you-should-join-us-top-space {
    margin: 130px 0px 22px 0px !important;
  }
  .stylist-top-space {
    padding: 0px 15px !important;
  }
  .sidenav .closebtn {
    right: 70px !important;
  }
  .we-are-commited-right-padding {
    padding-right: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .home-feature-tag {
    font-size: 30px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 20px !important;
    padding-top: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }

  .work-around-image-2 {
    height: 400px !important;
    /* object-fit: cover; */
    margin-top: 20px;
  }
  .work-around-image-1 {
    height: 350px !important;
    /* object-fit: cover; */
  }
  /* .quality-service-image {
    width: 346px !important;
  } */
  .faq-card-height {
    height: 60px !important;
    overflow-y: hidden !important;
  }
  .collapse-button-faq {
    height: 30px !important;
  }
  .faq-fz-12 {
    font-size: 12px;
  }

  .home-app-image2 {
    transform: translate(-76%, -18%) !important;
    top: 70px !important;
    overflow-y: hidden !important;
    height: 124px !important;
  }
  .card-height-people {
    height: 400px !important;
  }
  .home-dot-people-img-2 {
    transform: translateY(-90%) !important;
    right: 0px !important;
  }
  .home-look-title {
    font-size: 27px !important;
  }
  .here-to-help-you {
    margin-top: 200px !important;
  }
  .safety-helping-image {
    right: 1px !important;
  }
  .stylist-with-carry-second-image {
    width: 270px !important;
  }
  .we-also-have-image-size {
    width: 100% !important;
    height: auto !important;
    margin: 50px;
  }
  .stylist-iamge {
    height: 416px !important;
  }
  .fz-9-1024 {
    font-size: 9px !important;
    padding-top: 10px !important;
  }
  .btn-stylis-width {
    width: 96px !important;
    margin-left: 12px !important;
  }
  .checkbox-left-space-stylist {
    margin-left: 10px !important;
  }
  .Heres-an-right-padding {
    padding: 4px 0px 53px 0px !important;
  }
  .image-height-home-page {
    height: 400px !important;
  }
  .home-dot-img {
    bottom: 98px;
    left: 7%;
  }
  .padding-for-flexibility {
    padding: 50px 200px 0px 200px !important;
  }
  .home-count {
    font-size: 22px;
  }
  .For-a-limited-top-space {
    margin-top: 0px !important;
  }

  .your-safety-text-left-space {
    margin-left: 20px !important;
  }
  .We-have-combined {
    padding: 22px 80px 143px 80px !important;
  }

  .get-groomed-text-padding {
    height: 500px !important;
    padding: 0px 0px 0px 48px !important;
  }
  .we-are-commited-top-over-div {
    padding: 50px !important;
  }
  .width-100-on-768 {
    width: 100% !important;
  }
  .qulaity-text-left-sppace {
    margin-left: 0px !important;
  }
  .padding-left-20px-on-768 {
    padding-left: 20px !important;
  }
  .chhose-book-and-get-container-text {
    padding: 0px !important;
  }
  /* .chhose-book-and-get-image-container {
    height: auto !important;
} */
  .home-man {
    right: 25px;
    top: 21%;
  }
  .home-cng-subimg {
    top: 2%;
    right: 29%;
    height: 68px;
    width: 69px;
    overflow-y: hidden;
  }
  .home-woman {
    top: 29%;
    left: 10%;
  }
  .home-chart-img {
    left: 159px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .we-also-have-image-size {
    width: 431px !important;
  }
  .we-are-commited-image {
    width: 470px !important;
  }
  .count-top-space {
    padding-top: 50px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .home-feature-tag {
    font-size: 30px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 20px !important;
    padding-top: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }
  .counting-border-bottom-padding {
    padding: 50px !important;
  }
  .mx-w-90 {
    max-width: 88% !important;
  }
  .quality-service-image {
    padding: 20px;
  }
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .faq-card-padding {
    padding: 25px 18px 0px 10px !important;
  }

  .faq-card-height {
    height: 67px !important;
    overflow-y: hidden !important;
  }
  .collapse-button-faq {
    height: auto !important;
  }
  .home-app-image2 {
    top: 36px !important;
  }
  .card-height-people {
    height: 500px !important;
  }
  .home-count {
    font-size: 36px !important;
  }
  .home-dot-people-img-2 {
    transform: translateY(-91%) !important;
    right: 0px !important;
  }
  .home-dot-people-img {
    margin-top: 14% !important;
  }
  .home-look-title {
    font-size: 43px !important;
  }
  .home-man {
    right: -2px !important;
    top: 27% !important;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 26% !important;
  }
  .home-woman {
    top: 37% !important;
    left: 16% !important;
  }
  .home-chart-img {
    left: 254px !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .left-40-for-top-notification {
    left: 36% !important;
  }
  .form-side-top-space {
    padding-top: 30px;
  }
  .stylist-top-space {
    padding: 40px;
  }
  .fz-9-1024 {
    font-size: 12px !important;
  }
  .btn-stylis-width {
    width: 116px !important;
  }
  /* .stylist-top-space {
    padding: 40px;
} */

  .padding-for-flexibility {
    padding: 50px 50px 0px 0px !important;
  }
  .We-have-combined {
    padding: 22px 210px 143px 210px !important;
  }

  .get-groomed-text-padding {
    height: 491px !important;
    padding: 0px 0px 0px 20px !important;
  }
  .stylist-cng-subimg-1 {
    left: 40%;
  }
  .chhose-book-and-get-container-text {
    padding: 20px !important;
  }
  .home-dot-img {
    bottom: 31px !important;
    left: 14% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* .home_work_lines {
      bottom: 53% !important;
  } */
  .count-top-space {
    padding-top: 50px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .counting-border-bottom-padding {
    padding: 50px !important;
  }
  /* .quality-service-image {
      width: 103% !important;
  } */
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .home-app-image2 {
    transform: translate(-48%, -35%) !important;
    top: 42px !important;
  }
  .home-choose-back-img {
    margin-top: 136px !important;
    margin-left: 36px !important;
  }
  .home-dot-people-img-2 {
    transform: translateY(-90%) !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .btn-stylis-width {
    width: 114px !important;
  }
  .fz-10-1200 {
    font-size: 10px !important;
  }
  .padding-for-flexibility {
    padding: 50px 50px 0px 0px !important;
  }
  .chhose-book-and-get-container-text {
    padding: 20px 0px 0px 40px !important;
  }
  .home-dot-people-img2 {
    left: 37px !important;
  }
  .get-groomed-text-padding {
    height: 477px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .here-to-help-you {
    padding: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }
  .faq-page-top-margin {
    margin-top: 0px !important;
  }
  .people-container {
    margin: 130px auto 0px auto !important;
    padding: 20px;
  }
  .faq-btn-top-space {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .get-groomed-image {
    width: 100% !important;
    height: auto !important;
  }

  /* .We-have-combined {
    padding: 22px 20px 50px 20px  !important;
} */
  .what-we-offer-top-space {
    margin-top: 130px;
  }
  .available-to-download-top-space {
    padding: 0px 20px !important;
  }
  /* .how-it-works-text-padding {
    padding: 0px 20px !important;
} */
  /* .here-top-space {
    margin-top: 0px !important;
} */
  .home-apps-imgs {
    max-width: 60%;
  }
}

@media only screen and (max-width: 1025px) {
  .footer-unique-text {
    width: 100%;
  }
}
/* @media only screen and (min-width: 992px) {
    .some-extra-padding
  } */

@media only screen and (max-width: 992px) {
  .footer-unique-text {
    width: 100% !important;
  }

  .home-apps-imgs {
    width: 40% !important;
  }

  /* .home-header-sub-btn {
    width: 50%;
  } */
}

@media only screen and (min-width: 992px) {
  .footer-box-underline {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 1px solid #8c8b8b;
    opacity: 0.3;
    width: 20%;
    display: none;
  }

  .footer-image {
    width: 100%;
  }

  .footer-box-underline {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 0.5px solid #8c8b8b;
    opacity: 0.3;
    width: 20%;
    display: none;
  }

  .home-header-btn {
    border: 1px solid #000;
    color: #000;
    font-size: 10px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .side-barhidden-on-largcsreen {
    display: none;
  }
  .disply-none-on-large-screen-burgure {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .display-none-after-992px {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .display-none-under-991px {
    display: none !important;
  }

  .navbar-dark .navbar-toggler {
    display: none !important;
  }

  .navbar-light .navbar-toggler {
    display: none;
  }
  .w-100-991-common {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .stylist-faq-padding {
    padding: 0px 15px 0px 15px !important;
  }
  .modal-content {
    padding: 20px !important;
    width: 80% !important;
  }
  .footer-playstore-button-width {
    margin: 22px 0px;
  }
  .footer-making-text {
    margin-bottom: 22px;
  }
  .joe-bridges-photo {
    width: 100% !important;
    height: auto !important;
    /* object-fit: none; */
  }
  .safety-page-bottom-margin {
    margin-bottom: 48px;
  }
  .safety-footer-text {
    padding-top: 30px;
  }
  .home-work-icon-title {
    margin-top: 24px;
  }
  .home-imgcard-contents {
    font-size: 14px !important;
  }
  .safety-card-bottom-space-45px {
    padding-top: 10px;
    padding-bottom: 45px;
  }
  .safety-card-top-space-45px {
    padding-top: 45px;
  }
  .home-work-desc {
    padding: 0px 20px;
  }
  .space-bw-cards {
    margin-top: 20px !important;
  }
  .home-imgcard-contents {
    margin: 24px 0px;
  }
  .we-are-commited-right-padding {
    padding-top: 45px;
  }
  .flexibility-heading-top-space {
    margin-top: 22px !important;
  }

  .fz-26px-flexibility {
    font-size: 18px !important;
  }
  .stylist-header-heading {
    font-size: 32px !important;
    line-height: 45px;
  }
  .home-safety-card-title {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .home-choose-headings2 {
    font-size: 18px !important;
  }
  .home-look-title {
    font-size: 32px !important;
  }
  .about-meet-text {
    font-size: 32px !important;
  }
  .leave-us-lorem-text-form {
    padding: 19px 10px 50px 10px !important;
  }
  .leave-us-lorem-text-form {
    margin: 19px 0px 0px 0px !important;
  }

  .padding-top-for-20px-safety-image {
    padding: 50px 0px 0px 0px;
  }
  .work-around-main-div-top-and-bottom-space {
    margin: 130px 0px 130px 0px !important;
  }
  .customer-safety-bottom-button-padding {
    padding: 22px 0px 20px 0px !important;
  }
  .building-a-safer-padding {
    padding: 0px 20px 0px 20px !important;
  }
  .k50-conatiner-padding {
    padding: 80px 80px 0px 80px !important;
  }
  .we-also-have-a-greate-padding {
    padding: 0px 20px 34px 20px !important;
  }
  .why-you-should-join-us-top-space {
    margin: 0px 0px 22px 0px !important;
  }
  .safety-image {
    margin-top: 30px !important;
  }
  .covid-safety-top-space {
    margin: 0px 0px 32px 0px !important;
  }
  .safety-protocol-top-space {
    margin-top: 130px !important;
  }
  .Heres-an-right-padding {
    padding: 4px 0px 53px 0px !important;
  }
  .covid-19-door-top-div-space {
    margin-top: 130px !important;
  }
  .covid-19-padding {
    padding: 20px !important;
  }
  .container-for-image-card {
    padding: 0px !important;
    width: 90%;
    /* text-align: center; */
  }
  .home-feature-tag {
    font-size: 18px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 0px !important;
    padding-top: 0px !important;
    text-align: center;
  }
  .our-feature-top-space {
    padding-top: 0px !important;
  }
  .available-to-download-top-space {
    margin-top: 5px !important;
  }
  .how-it-works-top-space {
    margin: 5px 0px !important;
  }
  .safety-is-our-key-priority-top-space {
    margin-bottom: 100px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding: 0px 30px !important;
  }
  .safety-footer-heading {
    font-size: 32px !important;
  }
  .miled-stone-text-top-space {
    margin-top: 0px !important;
  }
  .refferal-reaward-top-space {
    margin-top: 20px !important;
  }
  /* .chhose-book-and-get-container {
    margin: 50px auto 0px auto !important;
} */
  .stylist-top-space {
    margin-top: 0px !important;
    padding: 0px 10px;
  }
  .why-choice-top-space {
    margin-top: 130px;
  }
  .width-for-people-text {
    width: 100% !important;
  }
  .your-safety-div-top-space {
    margin-top: 50px !important;
  }
  .top-margin-for-install-our-app {
    margin-top: 30px;
  }
  .work-around-image-2 {
    /* Height:300px !important; */
    /* object-fit: cover; */
    margin-top: 20px;
  }
  /* .work-around-image-1 {
    Height: 300px !important;
    object-fit: cover;
} */
  .elements-text-align-center-under-767 {
    text-align: center;
  }
  .element-center-under-767px {
    margin: 0px auto !important;
  }
  .width-90-under-767-px {
    width: 90% !important;
  }
  .width-50-for-what-cutomer-text-on-small {
    width: 50%;
  }
  .home-dot-people-img {
    margin-top: 11% !important;
  }
  .margin-0-for-center-image-smallscreen {
    margin: 0px auto !important;
  }
  .padding-for-flexibility {
    padding: 30px 15px 0px 15px !important;
  }
  .card-height-people {
    height: 400px !important;
  }
  .home-count {
    font-size: 26px !important;
  }
  .here-to-help-you {
    margin-top: 200px !important;
    padding: 0px 20px !important;
  }
  .stylist-safety-image-top-space-safety {
    margin-top: 80px;
  }
  .customer-safety-image {
    width: 100% !important;
    height: auto !important;
  }
  .we-also-have-image-size {
    width: 100% !important;
    height: auto !important;
    margin-top: 20px;
  }
  .get-groomed-text-padding {
    height: auto !important;
    padding: 50px 20px 0px 20px !important;
  }
  .why-join-us-image {
    margin-top: 50px;
  }
  .people-card-top-space {
    margin-top: 50px !important;
  }

  .we-are-commited-image {
    margin-top: 50px !important;
  }
  .we-are-commited-top-over-div {
    padding: 20px !important;
  }
  .comfot-and-easetop-space {
    margin-top: 30px !important;
  }
  .qulaity-text-left-sppace {
    margin-left: 0px !important;
  }
  .why-choose-text-top-space {
    margin-top: 100px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 50px !important;
  }
  .home-people-title {
    width: 100%;
  }
  .choose-main-container-top-space {
    margin-top: 130px !important;
  }
  .your-safety-text-left-space {
    margin: 0px auto !important;
    text-align: center;
    padding: 0px 10px !important;
  }
  .We-have-combined {
    padding: 22px 20px 50px 20px !important;
  }
  .quality-service-image {
    width: 100% !important;
    height: auto !important;
    margin: 0px auto;
    text-align: center !important;
  }
  .dispaly-none-on-mobile-under-768 {
    display: none !important;
  }

  .pr-35-991 {
    padding-right: 30px !important;
  }
}
.dark-margin-auto {
  margin: 0px auto !important;
}
/* ============== */
/* ================for-header============= */

/* ==============home-content============= */
.image-height-home-page {
  height: 465px;
  overflow-y: hidden;
}
.chhose-book-and-get-container {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-faq {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-safety {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-contact-us {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-top-space {
  margin-top: 60px !important;
}
.chhose-book-and-get-container-text {
  padding: 40px 0px 0px 70px;
}
/* .chhose-book-and-get-image-container{
  height: 584px;
} */
.here-top-space {
  margin-top: 96px;
}
.how-it-works-top-space {
  margin: 22px 0px;
}
.install-our-app-div {
  margin-top: 30px;
  overflow-y: hidden;
}
.install-our-app-icon-size {
  height: 120px;
  width: 120px;
}
.available-to-download-top-space {
  margin-top: 16px;
  padding: 0px 45px;
}
.what-we-offer-top-space {
  margin-top: 151px;
}
.our-feature-top-space {
  padding-top: 13px;
}
.We-have-combined {
  padding: 22px 250px 143px 250px;
  color: #6b6b6b;
  opacity: 0.7;
  line-height: 30px !important;
}
.quality-service-image {
  width: 450px;
  height: auto;
}
.qulaity-services-left-padding {
  padding-right: 164px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.what-we-offer-container {
  max-width: 1140px;
  margin: 151px auto 0px auto;
}
.your-safety-div-top-space {
  margin-top: 108px;
}
.your-safety-text-left-space {
  margin-left: 0px;
}

.why-choose-div-top-space {
  margin-top: 134px;
}
.dot-image {
  width: 146.16px;
  height: 165.56px;
}
.dot-image-position {
  position: relative;
  top: 127px;
  right: 25px;
}
.qulaity-text-left-sppace {
  margin-left: 40px;
}
.why-choose-text-top-space {
  margin-top: 118px;
}
.comfot-and-easetop-space {
  margin-top: 81px;
}
.setisfaction-text-bottom-space {
  margin-bottom: 80px;
}
.container-for-image-card {
  max-width: 1140px;
  margin: 138px auto 0px auto !important;
}
.we-are-commited-image {
  width: 532px;
  height: 544.17px;
}
.we-are-commited-right-padding {
  padding-right: 12px;
}
.we-are-commited-top-over-div {
  padding: 60px;
}
.people-container {
  max-width: 1140px;
  margin: 150px auto 0px auto;
}
.people-card-top-space {
  margin-top: 107px;
}
.home-dot-people-img2 {
  margin-top: 140px;
  left: 126px;
}
.home-people-section-c-upper {
  border-radius: 20px !important;
  background-color: white;
}
.home-people-section-c {
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 20px 20px !important;
}
.hover-for-card :hover {
  box-shadow: 0px 70px 80px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px !important;
}
.card-height-people {
  height: 550px;
}
.why-choice-top-space {
  margin-top: 0px;
}
.why-join-us-image {
  width: 641px;
  height: 483px;
}
.padding-for-flexibility {
  padding: 104px 16px 0px 0px;
}
.flexibility-safety-top-space {
  margin-bottom: 34px;
}
.flexibility-its-text-space-bw {
  margin-top: 10px;
}
.get-groomed-image {
  width: 85% !important;
  height: auto !important;
  margin-left: 30px;
  /* object-fit: none; */
}
.get-groomed-top-space {
  margin-top: 130px;
}
.For-a-limited-top-space {
  margin-top: 46px;
}
.get-groomed-text-padding {
  height: 500px;
  padding: 0px 0px 0px 111px;
}
.customer-testimonials-top-space {
  margin-top: 140px;
}
.customer-testimonials-top-space-for-faq {
  margin-top: 70px;
}
.frequently-asked-space {
  padding: 20px 0px 90px 0px;
}
/* .faq-card-height{
  height: 110px;
} */
.faq-card-padding {
  padding: 2% 4%;
}
.space-bw-cards {
  margin-top: 30px;
}
.faq-btn-top-space {
  margin-top: 75px;
  margin-bottom: 176px;
}
.counting-border-bottom-padding {
  padding-bottom: 113px;
}
.width-for-people-text {
  width: 584px;
}
.choose-main-container-top-space {
  margin-top: 169px;
}
.collapse-button-faq {
  height: 74px;
}
/* ===================stylist=============== */
.stylist-top-space {
  margin-top: 71px;
}
.Heres-an-right-padding {
  padding: 4px 90px 53px 0px;
}
.stylist-form-top-space {
  margin-top: 40px;
}
.fz-12 {
  font-size: 12px;
}
.btn-stylis-width {
  width: 121px;
}
.join-now-btn-top-space {
  margin-top: 24px;
}

.stylist-iamge {
  width: 561px;
  height: 471px;
}
.why-you-should-join-us-top-space {
  margin: 171px 0px 22px 0px;
}
.we-are-incredible-padding {
  padding: 0px 225px 20px 225px;
}
.flexibility-box-shadow {
  box-shadow: 0px 38.2353px 30.5882px rgba(0, 0, 0, 0.02);
}
.flexibility-heading-top-space {
  margin-top: 39px;
}
.take-on-new-top-space {
  margin-top: 20px;
}
.we-also-have-a-greate-padding {
  padding: 142px 300px 34px 300px;
}
.miled-stone-top-space {
  margin-top: 61px;
}
.refferal-reaward-top-space {
  margin-top: 76px;
}
.we-also-have-image-size {
  width: 511px;
  height: 400px;
}
.miled-stone-text-top-space {
  margin-top: 37px;
}
.padding-for-text-below-milestone {
  padding: 0px 104px 0px 0px;
}
.stylist-love-us-margin-top {
  margin-top: 142px;
}
.here-what-text-top-psace {
  margin: 29px 0px 88px 0px;
}
.stylist-love-slider-width {
  width: 920px;
  /* height: 430px; */
  margin: 0px auto;
}
.stylist-love-slider-shadow {
  box-shadow: 0px 52px 54px rgba(65, 62, 101, 0.102519);
}
.k50-conatiner-padding {
  padding: 80px;
}
.slider-lorem-text-padding {
  padding: 29px 45px;
}
.stylist-faq-collapse-space {
  margin-top: 25px;
}
.stylist-faq-lorem-text-padding {
  padding: 22px 250px 51px 250px;
}
.stylis-last-btn-align-right {
  margin: 51px 0px 96px auto;
}
.stylist-password-border-left {
  border-right: 1px solid #f8f8f8 !important;
}
/* ==================faq================ */
.faq-page-top-margin {
  margin-top: 81px;
  margin-bottom: 30px;
}
.faq-search-bar-height {
  height: 80px;
  max-width: 821px;
  padding: 6px 10px 0px 10px;
  margin: 0px auto;
}
.faq-search-bar-button-padding {
  padding: 13px;
}
.faq-card-row-top-space {
  margin-top: 55px;
}
.collapse-button-faq-50 {
  height: 50px;
}
.customize-bottom-cards-faq-768 {
  width: 100%;
  margin-left: 0px;
}
/* =============safety============== */
.safety-top-sapce {
  margin-top: 73px;
}
.safety-containerr-inner-padding {
  padding: 90px 103px 103px 103px;
}
.safety-protocol-top-space {
  margin-top: 178px;
}

.padding-for-safety-icons {
  padding: 0px 50px 54px 50px;
}
.padding-forboth-customer {
  padding: 16px 10px 0px 9px;
}
.covid-safety-top-space {
  margin: 100px 0px 32px 0px;
}
.stylist-with-carry-second-image {
  width: 318px;
  height: 332px;
  border-radius: 30px;
  position: relative;
  top: 40px;
}
.stylist-with-carry-third-image {
  width: 160px;
  height: 167px;
  border-radius: 30px;
  margin-top: 140px;
}
.safety-and-constantly-paddinf {
  padding: 43px 0px 100px 87px;
}
.safety-photos-top-space {
  margin-top: 35px;
}
.safety-helping-image {
  width: 107px;
  height: 109px;
  position: absolute;
  top: 0px;
  right: 59px;
  z-index: 99;
}
.hhelping-image-div {
  height: 373px;
}
.building-a-safer-padding {
  padding: 0px 20px 100px 20px;
}
.building-a-safer-text-padding {
  padding: 0px 100px 0px 20px;
}
.customer-safety-image {
  width: 194px;
  height: 172.31px;
}
.customer-safety-below-text {
  padding: 32px 0px 22px 0px;
}
.customer-safety-bottom-button-padding {
  padding: 22px 0px 0px 0px;
}
/* ======customer-safety============= */
.covid-19-door-top-div-space {
  margin-top: 174px;
}
.covid-19-door-bg {
  height: 120px;
  width: 120px;
  margin: 0px auto;
  background-color: white;
  box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.02);
  border-radius: 43.2778px;
}
.covid-19-icon-top-space {
  margin-top: 40px;
}
.safetyis-our-key-priority-right-padding {
  padding-left: 87px;
}
.both-the-safetytop-space {
  margin-top: 29px;
}
.width-inherit {
  width: 100%;
  height: auto;
}
.safety-is-our-key-priority-top-space {
  margin-top: 204px;
  margin-bottom: 100px;
}
.reviews-and-text-space {
  padding: 24px 0px 16px 0px;
}
.customer-safety-bottom-space-div {
  padding-bottom: 205px;
}
/* ===========about us =================== */
.about-us-top-space {
  margin: 55px auto 0px auto !important;
}
.about-us-lorm-ispum-padding {
  padding: 14px 220px 74px 220px;
}
.about-us-top-image {
  width: 821px;
  height: 579px;
}
.with-lots-of {
  padding: 20px 68px 75px 10px;
}
.work-around-image-1 {
  width: 100%;
  height: auto;
  border-radius: 27.7778px;
}
.work-around-image-2 {
  width: 100%;
  height: auto;
  border-radius: 27.7778px;
}
.dedicated-row-top-space {
  padding: 120px 0px 0px 0px;
}
.work-around-main-div-top-and-bottom-space {
  margin: 128px 0px 104px 0px;
}
.with-lots-of-unique-padding {
  padding: 25px 260px 51px 260px;
}
.joe-bridges-photo {
  width: 267px;
  height: 356px;
}
.joe-bridges-text-padding {
  padding: 25px 0px 4px 0px;
}
.founder-text-ottom-space {
  padding-bottom: 60px;
}
.apply-now-link-top-space {
  margin-top: 23px;
}
.intrsted-to-join-top-space {
  margin-top: 50%;
}
.have-any-que-main-div-space {
  margin: 78px 0px 132px 0px;
}
.dont-be-stranger-padding {
  padding: 27px 90px 49px 90px;
}
/* ===============contact us============ */
.here-to-help-you {
  margin-top: 100px;
  padding: 0px 50px 0px 50px;
}
.here-to-help-you-lorem-text {
  margin-top: 19px;
  opacity: 0.7;
}
.contact-us-card-position {
  position: relative;
  bottom: 180px;
}
.cntact-uform-position {
  position: relative;
  bottom: 100px;
}
.form-checkox-padding {
  padding: 0px 20px 0px 40px;
}
.contact-us-container-new {
  max-width: 722px;
  margin: 0px auto;
}
.leave-us-lorem-text-form {
  margin: 19px 0px 94px 0px;
}
.input-fields-form-padding {
  padding: 0px 20px;
}
.dontbe-stranger-padding {
  padding: 5% 0px 0px 0px;
}
.about-us-left-image {
  background-image: url("../src/assests/about_icons_2.png");
  background-position: left center;
  background-repeat: no-repeat;
  top: 15px;

  /* width: 400px;
    height: 400px; */
  /* position: relative; */
  /* top: 20px; */
  /* overflow-y: hidden; */
}
.about-us-right-image {
  background-image: url("../src/assests/about_icons_1.png");
  /* width: 400px;
  height: 400px; */
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  top: 50px;
}
.height-foraboust-us-icon-1 {
  height: 231px;
  width: 220px;
  position: relative;
  right: 31px;
}
.height-foraboust-us-icon-2 {
  height: 231px;
  width: 220px;
  position: relative;
  left: 30px;
  top: 25px;
}
.dont-be-last-margin-bottom {
  margin-bottom: 46px;
}
.login-container {
  max-width: 600px;
  margin: 0px auto;
}
/* ==============side-bar============= */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.sidenav a:active {
  color: rgba(0, 0, 0, 0.9) !important;
}

.sidenav .nav-link.active {
  color: rgba(0, 0, 0, 0.9) !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* side-bar */
.sidenav {
  /* height: fit-content; */
  width: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  padding-bottom: 100px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 35px;
  font-size: 36px;
  margin-left: 30px;
}
.burger-menue {
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.margin-top-60px-for-row-stylist {
  margin-top: 50px;
  padding: 0px 20px;
}
.people-card-photo-padding {
  padding: 24px 0px 24px 0px !important;
}

.text-bold-grey {
  color: #212529 !important;
}

.our-feature-section-margin-adjust {
  margin: 0px auto !important;
}
.COVID-19-Door-to-Door-Safety-content-padding {
  padding: 0px 0px 92px 0px;
}

/* ======modal========== */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 15%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 50px;
  border: 1px solid #888;
  width: 30%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* 
#toast-container>div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px;  
} */
.toast {
  top: 20% !important;
}
.stylist-checkbox:focus {
  box-shadow: none !important;
}
.modal-thankyou-text {
  font-size: 22px;
  line-height: 36px;
  color: #6b6b6b;
  opacity: 0.7;
}

.dotted-relative-image {
  position: relative;
  left: 30px;
}

.home-dotted-line-bottom {
  bottom: 53%;
}
.navbar-brand-height {
  height: 24px;
}
.navbar-brand-height-for-footer {
  height: 28px;
}
.download-now-button-width {
  width: 70%;
}
.become-astylist-button-padding {
  padding: 6.9px;
}
.play-store-on-mobile {
  width: auto;
  margin-left: 34px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.padding-for-home-faq {
  padding: 0px 30px 0px 20px;
}
.stylist-faq-padding {
  padding: 0px 40px 0px 40px;
}
.join-content-margin {
  margin: 16px auto 0px auto !important;
}

@media (max-width: 992px) {
  .we-are-incredible-padding{
    padding: 0;
  }
  .accordion .col-lg-11 {
    max-width: none !important;
  }
}

.home-work-desc.d-block.text-center.justify-content-center.feature.if-your-padding {
  padding-top: 16px;
}

@media (min-width: 992px){
.faq-container .col-lg-11 {
    max-width: none !important;
}
.stylist-faq-container .col-lg-11 {
  max-width: none !important;
}
}
