.contact-content {
  position: relative;
  text-align: center;
  color: white;
  z-index: -1;
}

.contact-centered {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-centered > span {
  font-size: 48px;
  font-weight: 600;
}

.contact-card-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 600;
}

.contact-card-desc {
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #6b6b6b;
}

.contact-card-btn {
  background-color: #000 !important;
  font-size: 11px !important;
  color: #fff;
  border-radius: 50px !important;
}

.contact-card {
  border-radius: 20px;
}

.contact-create-account {
  text-align: center;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  /* margin-top: 15%; */
}

.contact-desc {
  text-align: center;
  font-size: 15px;
  line-height: 32px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #6b6b6b;
  letter-spacing: 0.5px;
}
.contact-first-name,
.contact-Last-name,
.contact-email-name,
.contact-contact-name,
.contact-help-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.form-pass-content > input {
  width: 100%;
  height: 60px;
  border: 1px solid #e2e2e2 !important;
  border-right: none !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.form-icon {
  border: 1px solid #e2e2e2 !important;
  border-left: none !important;
}

.form-pass-content > input:focus,
.form-pass-content > input:active {
  border: 1px solid #e2e2e2;
  border-right: none !important;
}

.contact-first-input:focus,
.contact-help-input:focus,
.contact-Last-input:focus {
  border: 1px solid #e2e2e2 !important;
}

.contact-first-input,
.contact-Last-input {
  width: 100%;
  height: 60px;
  border: 1px solid #e2e2e2;
  outline: none;
  border-radius: 5px;
}
.contact-help-input {
  width: 100%;
  height: 280px;
  border: 1px solid #e2e2e2;
  outline: none;
  border-radius: 5px;
}

.contact-agree-name {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #31312e;
}
.contact-submit-btn {
  margin-top: 25px;
  width: 221px;
  height: 58px;
  font-size: 14px;
  line-height: 18px;
  background: #000000;
  color: #ffffff;
  border-radius: 50px;
  outline: none;
  border: none;
}

.contact-bg-img {
  width: 100%;
  height: 690px;
}

/* checkbox  */

.stylist-checkbox {
  position: absolute;
  opacity: 0;
}
.stylist-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.stylist-checkbox + label:before {
  content: "";
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #000;
  border-radius: 5px;
}
.stylist-checkbox:hover + label:before {
  background: #fff;
}
.stylist-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px #fff(0, 0, 0, 0.12);
}
.stylist-checkbox:checked + label:before {
  background: #fff;
}
.stylist-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.stylist-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.stylist-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000,
    4px -6px 0 #000, 4px -8px 0 #000;
  transform: rotate(45deg);
}

.contact-form-container {
  margin-bottom: 10%;
}

.contact-card-content {
  margin-top: -10%;
}



@media only screen and (min-width: 992px) {
  .contact-submit-btn {
    margin-top: 0px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .contact-bg-img {
    width: 1000px;
  }
  .contact-main-title {
    color: rgba(255, 255, 255);
  }
}

.contact-us-card{
  position: relative;
  bottom: 135px;
}