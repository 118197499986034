.home-common {
  background-color: #edf1f5;
  z-index: -2;
}

.home-img {
  width: 100%;
}

.home-app-img {
  width: initial;
  margin: auto;
}

.home-work-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #31312e;
}

.home-work-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 64.44px;
  color: #000;
}

.home-work-title2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  color: #000;
}

.faq-container .home-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
  /* max-width: 617px; */
  margin: auto;
}

.faq-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
  margin: auto;
  max-width: 617px;
}

.home-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
}

.home-container .home-work-desc {
  max-width: 617px;
}

.home_work_lines {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px dashed #d5d7dd;
  width: 70%;
  bottom: 30%;
}

.home-imgcard-desc {
  font-size: 16px;
  color: #6b6b6b;
  font-weight: 400;
  line-height: 32px;
}

.home_work_icons {
  z-index: 1;
  width: 85px;
  height: 85px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.home-work-icon-title {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  line-height: 35.56px;
}

.home-feature-tag {
  font-size: 42px;
  font-weight: 600;
  width: 75%;
  line-height: 56.7px;
  color: #000;
}

.home-choose-title {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  width: 50%;
}

.home-work-icon-title .my-4 {
  color: #6b6b6b;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
}

.home-choose-headings {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}

.home-choose-headings2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  color: #000;
}

.home-choose-desc {
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.homeChoose-width {
  width: 147%;
}
.home-choose-back-img {
  margin-top: 143px;
  margin-left: 48px;
}

.stylist-cng-subimg-1 {
  top: 55% !important;
}

.home-count {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #000;
}

.home-count-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #31312e;
  /* margin-right: -19px; */
  /* margin-top: 10px; */
}

.home-img-card {
  border-radius: 50px;
}

.home-img-card > div > span:nth-child(1) {
  color: #6b6b6b;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.home-img-card > div > div span:nth-child(1) {
  font-weight: 700;
  font-size: 45px;
  line-height: 62px;
}

/* .home-btns */

.home-btns:active,
.home-btns:focus {
  box-shadow: none;
  background-color: transparent;
  color: #000;
  outline: none;
}

.home-btns:hover {
  background-color: #000;
  color: #fff !important;
}

.home-join-btns {
  background-color: #000 !important;
  color: #fff;
}

.home-join-btns:focus {
  box-shadow: none;
  outline: none;
  color: #fff;
  background-color: #000;
}

.home-join-btns:active,
.home-join-btns:hover {
  color: #000;
  background-color: #fff;
}

.home-imgcard-subimg > img {
  bottom: 0;
  right: 0;
  width: 200px;
}

.home-img-main-content > div {
  bottom: 0;
}

.home-cng-subimg {
  top: 90;
  border-radius: 25px;
  animation: cngUpdown 3s linear infinite;
  position: absolute;
  top: 13%;
  right: 27%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.home-cng-subimg > div {
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.61);
  top: 90;
  border-radius: 25px;
}

.home-cng-subimg > div > div {
  width: 55px;
  height: 55px;
  background-color: rgba(250, 250, 250);
  bottom: 0;
  border-radius: 15px;
}

.home-cng-subimg > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.home-cng-subimg > div > div > div > img {
  width: 80%;
}

.home-join-icons {
  z-index: 1;
  width: 43px;
  height: 43px;
  background-color: #000;
  border-radius: 50%;
  /* box-shadow: 0px 6px 0px #e6e1e17d; */
}

.home-app-download {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #31312e;
}

.home-apps-img > img {
  width: inherit;
}

.home-faq-content-btn > button:focus {
  box-shadow: none;
  outline: none;
}

.home-faq-title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 1.5rem !important;
  text-align: center;
}

.home-faq-desc {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.home-people-section {
  transition: 0.5s all;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.06) !important;
  margin-top: -56px;
}

.home-people-section:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.home-faq-card-details {
  border-radius: 15px !important;
}

.home-faq-details {
  font-size: 14px;
  color: #6b6b6b;
  max-height: 0px;
  overflow: hidden;
  line-height: 25px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.stylist-faq-details {
  font-size: 13px;
  color: #6b6b6b;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 0, 0, 0);
}

.home-faq-details.home-faq-show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.stylist-faq-details.stylist-faq-show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(0, 0, 0, 0);
}

.home-faq-extend-title {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  /* margin: 0px -18px !important; */
}

.stylistpage-faq-extend-title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.stylist-faq-details {
  font-size: 14px;
  line-height: 25px;
}

.home-faq-btn {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 40px;
  height: 40px;
  border-radius: 50px !important;
  background-color: #fff;
}

.home-faq-btn:focus,
.home-faq-btn:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 40px;
  height: 40px;
  border-radius: 50px !important;
}

.home-woman {
  animation: MoveUpDown 3s linear infinite;
  position: absolute;
  top: 38%;
  left: 17%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 38%;
}

.home-chart-img {
  position: relative;
  left: 300px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 88%;
}

.home-dot-img {
  bottom: 6px;
  left: 15%;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.home-imgcard-contents {
  font-size: 16px;
  line-height: 32px;
  color: #6b6b6b;
  font-weight: 400;
  margin: 20px 0px;
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.home-man {
  animation: MoveDownUp 3s linear infinite;
  position: absolute;
  right: -10px;
  top: 33%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 38%;
}

@keyframes MoveDownUp {
  0%,
  100% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(0);
  }
}

@keyframes cngUpdown {
  0%,
  100% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(0);
  }
}

.home-people-title {
  width: 55%;
}

.home-people-heading {
  font-size: 35px;
  font-weight: 700;
}

.home-dot-people-img {
  margin-top: 11%;
  left: -17px;
  width: 146px;
  height: 165px;
}

.home-dot-people-img-2 {
  transform: translateY(-70%);
  right: 0px;
}

.home-app-image {
  transform: translate(-40%, -35%);
  z-index: -1;
}

.home-app-image2 {
  transform: translate(-48%, -35%);
  z-index: -1;
  top: 51px;
}

.home-app-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #31312e;
}

.work-content-mob {
  display: none;
}

.work-content-desc > div > p {
  font-size: 16px;
  line-height: 30px;
  color: #6b6b6b;
  opacity: 0.7;
}

/* .work-content-desk {
    display: block;
  } */

.home-look-title {
  font-size: 48px;
  font-weight: 600;
  margin-right: -28px;
  color: #000;
}
.featere-desc {
  width: 100%;
}

.home-look-desc {
  font-size: 16px;
  font-weight: 400;
  color: #31312e;
  opacity: 0.7;
  line-height: 32px;
  margin: 16px 0px;
}

.home-feature-imgs {
  width: 30vw !important;
}

.home-people-imgs {
  width: 30% !important;
}

.home-people-img-desc {
  font-weight: 700;
  font-size: 18px;
  line-height: 24.39px;
}

.home-people-card-name {
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  line-height: 24.39px;
}

.home-people-card-desc {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  padding: 0 1rem;
}

.choose-bg {
  background-color: #edf1f5;
}

.learnmore {
  color: #000;
  text-decoration: none;
}

.learnmore:hover {
  color: #6b6b6b;
  text-decoration: none !important;
}

.stylist-header-heading {
  line-height: 70px;
  font-size: 48px;
  font-weight: 600;
  color: #000;
}

/* .home-app-content-appimg {
    width: 35% !important;
  } */

.feature-desc-text {
  color: #6b6b6b;
  font-size: 15px;
  line-height: 33px;
  width: 80%;
}

.home-work-container {
  margin-top: 8%;
}

.home-app-content-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 62px;
}

.home-safety-card-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 70.22px;
  color: #000;
  overflow-y: hidden;
}

.home-safety-card-title-width {
  width: 121%;
}
.count-top-space {
  padding-top: 93px;
}
.orders-left-space {
  margin-left: 10px;
}
.covid-19-padding {
  padding: 0px 133px;
}
.bg-black {
  background-color: black;
}

/* media query */
@media only screen and (max-width: 1199px) {
  .customer-icon-heading {
    font-size: 18px !important;
  }

  .home-more-faq {
    width: 100%;
  }

  .home-app-content-appimg {
    width: 48% !important;
  }

  .home-app-download {
    font-size: 15px;
    font-weight: 700;
  }

  .faq-icon-button {
    left: 82%;
    bottom: 48px;
  }

  .home-choose-title {
    width: 100%;
  }

  /* .home-count {
      font-size: 38px;
    }
   */
  .home-count-desc {
    font-size: 12px;
  }

  .home-count-box {
    box-shadow: 0px 0px 5px #808080;
  }

  .home-apps-img > img {
    width: 100%;
  }

  .home-feature-tag {
    width: 100%;
  }

  .home-people-title {
    width: 100%;
    font-size: 25px !important;
  }

  .content-imagecard,
  .content-appcontent {
    padding-left: 0%;
  }

  .home-work-container {
    margin-top: 0%;
  }
}

@media only screen and (max-width: 991px) {
  .margin-left-0-991px {
    margin-left: 0px !important;
  }
  .homeChoose-width {
    width: 100%;
  }

  .home-choose-back-img {
    display: none;
  }

  .home-work-desc {
    width: 53%;
  }

  .feature {
    width: 59%;
  }
  /* .home-people-title {
      width: 63%;
    } */

  /* .home-count-desc{
      margin-right: 67px;
    } */

  .home-safety-card-title-width {
    width: 139%;
  }

  /* .join-points{
      margin-right: 114px !important;
    } */

  .stylist-cng-subimg-1 {
    left: 4% !important;
    top: 55% !important;
  }
  .stylist-cng-subimg-1 > div {
    overflow-y: hidden;
  }

  /* .stylist-cng-subimg-1 > div > div{
      height: 60px !important;
      
    } */

  .home-join-now {
    float: right;
  }

  .featere-desc {
    width: 100%;
  }
}

@media only screen and (max-width: 1120px) {
  /* .home-dot-img{
      bottom: 24px;
    } */
  /* .home-chart-img{
      top: -1%;
    } */
}

@media only screen and (max-width: 960px) {
  /* .home-dot-img{
      bottom: 110px;
    } */
  /* .home-woman {
      top: 39%;
      left : 22%
    } */
  /* .home-man{
      right: -12px;
      top: 35%;
    } */
  /* .home-cng-subimg{
      top: 15%;
      right: 21%;
    } */
}

@media only screen and (max-width: 960px) {
  /* .home-look-title{
      font-size: 35px;
    } */
  .faq-icon-button {
    left: 79%;
  }
}
@media only screen and (max-width: 767px) {
  .faq-icon-button {
    left: 0%;
    bottom: -2px;
  }
  .home-safety-card-title-width {
    width: auto;
  }
  .w-121.responsive-faq {
    width: 100% !important;
  }
}

@media only screen and (max-width: 425px) {
  .safety-desc-text {
    padding-right: 0px !important;
  }
  .w-100-991-common {
    max-width: 100% !important;
  }
  .w-100-425 {
    width: 100% !important;
  }
  .join-points {
    margin-right: 0px !important;
    text-align: center;
  }
  /* .home-safety-card-title {
      font-size: 36px !important;
      line-height: 60px !important;
    } */
  /* .home-dot-img{
      bottom: 6px;
      left: 57px;
      width: 55%;
    } */
  /* .home-woman {
      left : 20%
    } */
  /* .home-man{
      right: 10px;
    } */
  /* .home-cng-subimg{
      top: -4%;
    } */
  /* .home-chart-img{
      top: -14%;
    } */
  .home-count-desc {
    margin-left: 1px;
    width: 100%;
  }

  .w-121.responsive {
    width: 147% !important;
  }
}

@media only screen and (max-width: 991px) {
  .count-top-space {
    padding-top: 50px !important;
  }
  .padding-y-0-768 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .contact-us-card {
    position: relative;
    bottom: 100px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .home-dot-people-img2 {
    margin-top: 86px !important;
    /* top: 629%; */
    left: -80px !important;
  }
  .contact-us-card {
    position: relative;
    bottom: 70px !important;
  }
}
@media only screen and (max-width: 768px) {
  .about-meet-descs {
    max-width: 100% !important;
  }

  .contact-centered {
    top: 18% !important;
  }
  .contact-us-card {
    position: relative;
    bottom: 0px !important;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-centered {
    top: 15% !important;
    width: 100% !important;
  }
  .contact-centered > span {
    font-size: 32px !important;
  }
}
.f.home-dot-people-img-2 {
  padding-bottom: 100px;
}
.home-faq-heading-button-padding {
  padding: 0px 7px;
}
