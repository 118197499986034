.signup-crreate-account {
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.signup-desc {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #6b6b6b;
  letter-spacing: 0.5px;
}

.signup-first-name,
.signup-Last-name,
.signup-email,
.signup-password,
.signup-Phone {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.signup-first-input,
.signup-Last-input {
  /* width: 100%; */
  height: 60px;
  border: 0.5px solid #d6d6d6;
  outline: none;
  border-radius: 5px;
}
.signup-first-input:focus,
.signup-Last-input:focus {
  border: 0.5px solid #000;
}

.signup-email-input,
.signup-password-input,
.signup-Phone-input {
  height: 60px;
  border: 0.5px solid #d6d6d6;
  outline: none;
  border-radius: 5px;
}

.signup-email-input:focus,
.signup-password-input:focus,
.signup-Phone-input:focus {
  border: 0.5px solid #000;
}

::placeholder {
  font-size: 14px;
}
.signup-btn {
  background-color: black;
  border-radius: 50px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
}
