.stylist-stylist-header-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 50px;
}

.stylist-header-form-title {
  font-weight: 700;
  font-size: 22px;
}

.stylist-header-form-reg-btn {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.stylist-header-form-reg-btn:hover {
  text-decoration: none;
  color: black;
}

.stylist-form > label {
  font-size: 12px;
  font-weight: 700;
}

.stylist-form > input {
  font-size: 14px;
  color: #000;
}

.stylist-form > input:focus,
.stylist-form > input:active {
  box-shadow: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0);
}


@media only screen and (max-width: 1368px) {
  .stylist-cng-subimg {
    padding-top: 90px;
    right: 1px;
  }
}

.stylist-cng-subimg {
  top: 90;
  right: 0%;
}

.stylist-cng-subimg > div {
  background-color: rgba(250, 250, 250, 0.6);
  top: 90;
  border-radius: 18px;
  animation: cngUpdown 2s linear infinite;
}

.stylist-cng-subimg > div > div {
  width: auto;
  height: 60px;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  border-radius: 15px;
}

.stylist-cng-subimg > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.stylist-cng-subimg > div > div > div > img {
  width: 80%;
}

/* --------------------- */
.stylist-cng-subimg-1 {
  /* padding-top: 130px; */
  left: 40%;
  top: 12%;
}

.stylist-cng-subimg-3 {
  top: 12% !important;
}
/* .stylist-feature{
  width: 118%;
} */

.stylist-cng-subimg-2 {
  top: 24% !important;
  left: 4% !important;
}

.stylist-cng-subimg-1 > div {
  background-color: rgba(250, 250, 250, 0.6);
  /* top: 90%; */
  border-radius: 18px;
  animation: cngUpdown 2s linear infinite;
}

.stylist-cng-subimg-1 > div > div {
  width: auto;
  height: 60px;
  background-color: rgb(255, 255, 255);
  /* bottom: 0; */
  border-radius: 15px;
}

.stylist-cng-subimg-1 > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.stylist-cng-subimg-1 > div > div > div > img {
  width: 80%;
}

.stylist-cng-subimg-details-1 > span:nth-child(1) {
  font-size: 10px;
  font-weight: 400;
}

.stylist-cng-subimg-details-1 > span:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
}

/* stylist-checkbox */

.stylist-checkbox {
  position: absolute;
  opacity: 0;
}

.stylist-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.stylist-checkbox + label:before {
  content: "";
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #000;
  border-radius: 5px;
}

.stylist-checkbox:hover + label:before {
  background: #000;
}

.stylist-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.stylist-checkbox:checked + label:before {
  background: #000;
}

.stylist-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.stylist-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.stylist-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.stylist-form-signin > div {
  font-size: 13px;
}

.stylist-form-signin > div > a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.stylist-form-chk-desc {
  font-size: 12px;
}

.stylist-form-chk-desc > span:nth-child(1),
.stylist-form-chk-desc > span:nth-child(2) {
  font-size: 13px;
  font-weight: 800;
}

.stylist-join-content-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 48px;
  color: #373F41;
}

.stylist-join-content-desc {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #6b6b6b;
}

.stylist-faq-content {
  border-radius: 15px;
}

.stylist-cng-subimg-details > span:nth-child(1) {
  font-size: 10px;
  font-weight: 400;
}

.stylist-cng-subimg-details > span:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
}

.stylist-customer-desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 39px;
}

.stylist-main-desc {
  line-height: 32px;
  font-size: 16px;
  color: #31312e;
  font-weight: 400;
}

.stylist-anim-content {
  width: 40px;
  height: 40px;
  background-color: #000;
}

.stylist-form-btn-text {
  font-size: 12px;
}

.stylist-customer-imgs {
  width: 18vw;
}

.slider-content {
  box-shadow: 0px 52px 54px rgba(65, 62, 101, 0.102519);
  border-radius: 10px;
}

.image-1 {
  border-radius: 4%;
}

.slider-desc-text {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 30px;
  width: 75%;
}

.slider-name-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.slider-post-text {
  font-weight: 450;
  font-size: 16px;
  line-height: 28px;
  color: #696871;
}

.image-1 {
  position: relative;
}

.stylist-slider-fadeIn {
  animation: slidetText 0.1s;
}

@keyframes slidetText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slider-previous-btn {
  width: 80px;
  height: 80px;
  border: none;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 99%;
  left: -11%;
  font-size: 20px;
}

:hover.slider-previous-btn {
  background-color: black;
  color: white;
}

.shape-image {
  width: 60%;
}

.slider-next-btn {
  width: 16vw;
  height: 80px;
  border: none;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: -8%;
  right: -10%;
  font-size: 20px;
}

:hover.slider-next-btn {
  background-color: black;
  color: white;
}

@media only screen and (max-width: 769px) {
  /* .stylist-feature {
    width: 60%;
  } */
  .home-join-icons.stylist{
    justify-content: center!important;
  }
  .icon-div-stylist{
    right: 7px;
  }
}

@media only screen and (max-width: 1025px) {
  /* .stylist-cng-subimg-1 {
    padding-top: 130px;
    right: 1%;
  } */

  .stylist-cng-subimg {
    padding-top: 10px;
    right: -20px;
  }

  .stylist-cng-subimg-1 > div {
    background-color: rgba(250, 250, 250, 0.6);
    top: 0%;
    border-radius: 18px;
    animation: cngUpdown 2s linear infinite;
    padding: 8px;
    /* height:50px; */
  }
}

@media only screen and (min-width: 992px) {
  .slider-previous-btn {
    width: 80px;
    border: none;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: -9%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  :hover.slider-previous-btn {
    background-color: black;
    color: white;
  }

  .slider-next-btn {
    width: 80px;
    border: none;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 39%;
    right: -25%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  :hover.slider-next-btn {
    background-color: black;
    color: white;
  }
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-prev {
  width: 14% !important;
  left: -55px !important;
  opacity: 1 !important;
}

.carousel-control-next {
  width: 14% !important;
  right: -55px !important;
  opacity: 1 !important;
}

/* .carousel-control-prev-icon { */
/* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important; */

/* } */

.slider-icons-prev-next {
  width: 65px;
  height: 65px;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  transition: 0.5s;
}

.slider-icons-prev-next:hover {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 768px) {
  

  .shape-image {
    width: 100%;
  }

  .carousel-control-prev {
    width: 14% !important;
    left: -15px !important;
    opacity: 1 !important;
  }

  .carousel-control-next {
    width: 14% !important;
    right: -15px !important;
    opacity: 1 !important;
  }

  .slider-desc-text {
    width: 100%;
  }

  .slider-previous-btn {
    top: 39%;
    left: 5%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  .slider-next-btn {
    top: 39%;
    right: 5%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  

  .slider-next-btn {
    top: 0%;
    transition-duration: 0.5s;
    height: 60px;
    width: 61px;
  }

  .slider-previous-btn {
    top: 102%;
    transition-duration: 0.5s;
    height: 60px;
    width: 61px;
  }

  .testimonials {
    max-height: 700px;
  }

  .icon-overlay {
    height: 300px;
    top: calc(50% - 150px);
  }

  .carousel-controls .control {
    width: 25px;
    height: 25px;
    top: inherit;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .control i {
    font-size: 0.7rem;
  }

  .testimonials .message {
    font-size: 1rem;
  }

  .testimonials h2 {
    font-size: 1.5rem;
  }

  .stylist-cng-subimg {
    display: none;
  }

  /* .stylist-cng-subimg-1 {
    display: none;
  } */
}
@media only screen and(min-width: 1024px) and (max-width: 1439px) {
  .stylist-join-content-title {
    font-size: 24px !important;
   }
  }
  
  @media only screen and(min-width: 768px) and (max-width: 1023px) {
    .slider-icons-prev-next {
      width: 60px;
      height: 40px;
      border-radius: 50%;
      color: #000;
    }
    .stylist-join-content-title {
      font-size: 18px !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .width-100-on-991{
      max-width: 100% !important;
    }
    .slider-image-991{
      height: 300px;
      width: 100%;
      padding-bottom: 20px;
      object-fit: none;
    }
  } 
  @media only screen and (min-width:768) and (max-width: 991px) {
    .stylist-join-content-title {
      font-size: 22px !important;
    }
    
  }

/* ---------------------------------------------------- */
