.about-font {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
}

.about-desc {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #161c2d;
}

.about-meet-descs {
  font-size: 18px;
  line-height: 32px;
  color: #161c2d;
  opacity: .7 !important;
  /* max-width: 50%; */
}

.about-last-container{
  background-color: #ffffff;
}

.about-box-work {
  width: 100%;
  background-color: #edf1f5;
  border-radius: 50px;
  padding: 105px 114px 106px 110px;
}
.about-work-text {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  color: #000000;
  padding: 0px 7px !important;
}
.about-unique-text {
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #161c2d;
}
.footer-image {
  border-radius: 40px;
}
.about-dedicated-text {
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #161c2d;
}
.about-dedicated-text-icon{
  position: absolute;
  width: 70%;
  top: 20px;
  left: 0;
}
.about-dedicated-desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  opacity: 0.7;
  color: #161c2d;
}
.about-meet-text {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  line-height: 58px;
  letter-spacing: 0.5px;
}
.about-image {
  border-radius: 10px;
}
.about-text-1 {
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.about-desc-1 {
  font-size: 17px;
  line-height: 29px;
  font-weight: 400;
  color: #161c2d;
}
.about-text-team {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #161c2d;
}
.about-desc-team {
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #000000;
}
.about-any-text {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  color: #64626a;
}
.about-any-desc-text {
  text-align: center;
  font-size: 46px;
  line-height: 60px;
  color: #000000;
  font-weight: 600;
}
.about-contact-btn {
  width: 160px;
  height: 48px;
  font-size: 14px;
  line-height: 18px;
  background: #000000;
  color: #ffffff;
  border-radius: 50px;
  outline: none;
  border: none;
}

.about-apply {
  cursor: pointer;
}

.about-tagline-img-2 {
  right: 0;
  transform: translate(0%, 0%);
  z-index: 1;
}

.about-last-sec {
  left: 0;
}

.about-last-sec-2 {
  right: 0;
}

.about-line {
  right: 0;
  -ms-transform: translate(0%, -10%);
  transform: translate(0%, 20%);
  z-index: 1;
  width: 300px;
}

.footer-img-size {
  width: 184px;
  height: 58px;
}

.footer-bg {
  background-color: #000;
}

.about-helping-main-content {
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (min-width: 992px) {

  .about-dedicated-text {
    font-size: 18px;
  }

  .about-bg-imgs {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .about-box-work {
    width: 100%;
    background-color: #edf1f5;
    border-radius: 50px;
  }

  .footer-image {
    width: 100%;
  }
}
