@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast {
  background-color: #030303 !important;
}
.toast-success {
  background-color: #51a351 !important;
}
.toast-error {
  background-color: #bd362f !important;
}
.toast-info {
  background-color: #2f96b4 !important;
}
.toast-warning {
  background-color: #f89406 !important;
}

.home-common {
  background-color: #edf1f5;
  z-index: -2;
}

.home-img {
  width: 100%;
}

.home-app-img {
  width: auto;
  width: initial;
  margin: auto;
}

.home-work-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #31312e;
}

.home-work-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 64.44px;
  color: #000;
}

.home-work-title2 {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  color: #000;
}

.faq-container .home-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
  /* max-width: 617px; */
  margin: auto;
}

.faq-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
  margin: auto;
  max-width: 617px;
}

.home-work-desc {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
  line-height: 30px;
}

.home-container .home-work-desc {
  max-width: 617px;
}

.home_work_lines {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px dashed #d5d7dd;
  width: 70%;
  bottom: 30%;
}

.home-imgcard-desc {
  font-size: 16px;
  color: #6b6b6b;
  font-weight: 400;
  line-height: 32px;
}

.home_work_icons {
  z-index: 1;
  width: 85px;
  height: 85px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.home-work-icon-title {
  font-weight: 700;
  font-size: 22px;
  color: #000;
  line-height: 35.56px;
}

.home-feature-tag {
  font-size: 42px;
  font-weight: 600;
  width: 75%;
  line-height: 56.7px;
  color: #000;
}

.home-choose-title {
  font-size: 38px;
  font-weight: 600;
  line-height: 48px;
  width: 50%;
}

.home-work-icon-title .my-4 {
  color: #6b6b6b;
  line-height: 30px;
  font-size: 16px;
  font-weight: 700;
}

.home-choose-headings {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  color: #000;
}

.home-choose-headings2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  color: #000;
}

.home-choose-desc {
  color: #6b6b6b;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
}
.homeChoose-width {
  width: 147%;
}
.home-choose-back-img {
  margin-top: 143px;
  margin-left: 48px;
}

.stylist-cng-subimg-1 {
  top: 55% !important;
}

.home-count {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #000;
}

.home-count-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: #31312e;
  /* margin-right: -19px; */
  /* margin-top: 10px; */
}

.home-img-card {
  border-radius: 50px;
}

.home-img-card > div > span:nth-child(1) {
  color: #6b6b6b;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}

.home-img-card > div > div span:nth-child(1) {
  font-weight: 700;
  font-size: 45px;
  line-height: 62px;
}

/* .home-btns */

.home-btns:active,
.home-btns:focus {
  box-shadow: none;
  background-color: transparent;
  color: #000;
  outline: none;
}

.home-btns:hover {
  background-color: #000;
  color: #fff !important;
}

.home-join-btns {
  background-color: #000 !important;
  color: #fff;
}

.home-join-btns:focus {
  box-shadow: none;
  outline: none;
  color: #fff;
  background-color: #000;
}

.home-join-btns:active,
.home-join-btns:hover {
  color: #000;
  background-color: #fff;
}

.home-imgcard-subimg > img {
  bottom: 0;
  right: 0;
  width: 200px;
}

.home-img-main-content > div {
  bottom: 0;
}

.home-cng-subimg {
  top: 90;
  border-radius: 25px;
  -webkit-animation: cngUpdown 3s linear infinite;
          animation: cngUpdown 3s linear infinite;
  position: absolute;
  top: 13%;
  right: 27%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.home-cng-subimg > div {
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.61);
  top: 90;
  border-radius: 25px;
}

.home-cng-subimg > div > div {
  width: 55px;
  height: 55px;
  background-color: rgba(250, 250, 250);
  bottom: 0;
  border-radius: 15px;
}

.home-cng-subimg > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.home-cng-subimg > div > div > div > img {
  width: 80%;
}

.home-join-icons {
  z-index: 1;
  width: 43px;
  height: 43px;
  background-color: #000;
  border-radius: 50%;
  /* box-shadow: 0px 6px 0px #e6e1e17d; */
}

.home-app-download {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #31312e;
}

.home-apps-img > img {
  width: inherit;
}

.home-faq-content-btn > button:focus {
  box-shadow: none;
  outline: none;
}

.home-faq-title {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 1.5rem !important;
  text-align: center;
}

.home-faq-desc {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.home-people-section {
  transition: 0.5s all;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.06) !important;
  margin-top: -56px;
}

.home-people-section:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.home-faq-card-details {
  border-radius: 15px !important;
}

.home-faq-details {
  font-size: 14px;
  color: #6b6b6b;
  max-height: 0px;
  overflow: hidden;
  line-height: 25px;
  transition: all 0.5s cubic-bezier(0, 1, 0, 1);
}

.stylist-faq-details {
  font-size: 13px;
  color: #6b6b6b;
  max-height: 0px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0, 0, 0, 0);
}

.home-faq-details.home-faq-show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

.stylist-faq-details.stylist-faq-show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(0, 0, 0, 0);
}

.home-faq-extend-title {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 25px;
  /* margin: 0px -18px !important; */
}

.stylistpage-faq-extend-title {
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
}

.stylist-faq-details {
  font-size: 14px;
  line-height: 25px;
}

.home-faq-btn {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 40px;
  height: 40px;
  border-radius: 50px !important;
  background-color: #fff;
}

.home-faq-btn:focus,
.home-faq-btn:active {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  width: 40px;
  height: 40px;
  border-radius: 50px !important;
}

.home-woman {
  -webkit-animation: MoveUpDown 3s linear infinite;
          animation: MoveUpDown 3s linear infinite;
  position: absolute;
  top: 38%;
  left: 17%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 38%;
}

.home-chart-img {
  position: relative;
  left: 300px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 88%;
}

.home-dot-img {
  bottom: 6px;
  left: 15%;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.home-imgcard-contents {
  font-size: 16px;
  line-height: 32px;
  color: #6b6b6b;
  font-weight: 400;
  margin: 20px 0px;
}

@-webkit-keyframes MoveUpDown {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
}

.home-man {
  -webkit-animation: MoveDownUp 3s linear infinite;
          animation: MoveDownUp 3s linear infinite;
  position: absolute;
  right: -10px;
  top: 33%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 38%;
}

@-webkit-keyframes MoveDownUp {
  0%,
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes MoveDownUp {
  0%,
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes cngUpdown {
  0%,
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes cngUpdown {
  0%,
  100% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  50% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.home-people-title {
  width: 55%;
}

.home-people-heading {
  font-size: 35px;
  font-weight: 700;
}

.home-dot-people-img {
  margin-top: 11%;
  left: -17px;
  width: 146px;
  height: 165px;
}

.home-dot-people-img-2 {
  -webkit-transform: translateY(-70%);
          transform: translateY(-70%);
  right: 0px;
}

.home-app-image {
  -webkit-transform: translate(-40%, -35%);
          transform: translate(-40%, -35%);
  z-index: -1;
}

.home-app-image2 {
  -webkit-transform: translate(-48%, -35%);
          transform: translate(-48%, -35%);
  z-index: -1;
  top: 51px;
}

.home-app-desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #31312e;
}

.work-content-mob {
  display: none;
}

.work-content-desc > div > p {
  font-size: 16px;
  line-height: 30px;
  color: #6b6b6b;
  opacity: 0.7;
}

/* .work-content-desk {
    display: block;
  } */

.home-look-title {
  font-size: 48px;
  font-weight: 600;
  margin-right: -28px;
  color: #000;
}
.featere-desc {
  width: 100%;
}

.home-look-desc {
  font-size: 16px;
  font-weight: 400;
  color: #31312e;
  opacity: 0.7;
  line-height: 32px;
  margin: 16px 0px;
}

.home-feature-imgs {
  width: 30vw !important;
}

.home-people-imgs {
  width: 30% !important;
}

.home-people-img-desc {
  font-weight: 700;
  font-size: 18px;
  line-height: 24.39px;
}

.home-people-card-name {
  font-weight: 400;
  font-size: 14px;
  color: #828282;
  line-height: 24.39px;
}

.home-people-card-desc {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  padding: 0 1rem;
}

.choose-bg {
  background-color: #edf1f5;
}

.learnmore {
  color: #000;
  text-decoration: none;
}

.learnmore:hover {
  color: #6b6b6b;
  text-decoration: none !important;
}

.stylist-header-heading {
  line-height: 70px;
  font-size: 48px;
  font-weight: 600;
  color: #000;
}

/* .home-app-content-appimg {
    width: 35% !important;
  } */

.feature-desc-text {
  color: #6b6b6b;
  font-size: 15px;
  line-height: 33px;
  width: 80%;
}

.home-work-container {
  margin-top: 8%;
}

.home-app-content-title {
  font-weight: 700;
  font-size: 45px;
  line-height: 62px;
}

.home-safety-card-title {
  font-weight: 600;
  font-size: 48px;
  line-height: 70.22px;
  color: #000;
  overflow-y: hidden;
}

.home-safety-card-title-width {
  width: 121%;
}
.count-top-space {
  padding-top: 93px;
}
.orders-left-space {
  margin-left: 10px;
}
.covid-19-padding {
  padding: 0px 133px;
}
.bg-black {
  background-color: black;
}

/* media query */
@media only screen and (max-width: 1199px) {
  .customer-icon-heading {
    font-size: 18px !important;
  }

  .home-more-faq {
    width: 100%;
  }

  .home-app-content-appimg {
    width: 48% !important;
  }

  .home-app-download {
    font-size: 15px;
    font-weight: 700;
  }

  .faq-icon-button {
    left: 82%;
    bottom: 48px;
  }

  .home-choose-title {
    width: 100%;
  }

  /* .home-count {
      font-size: 38px;
    }
   */
  .home-count-desc {
    font-size: 12px;
  }

  .home-count-box {
    box-shadow: 0px 0px 5px #808080;
  }

  .home-apps-img > img {
    width: 100%;
  }

  .home-feature-tag {
    width: 100%;
  }

  .home-people-title {
    width: 100%;
    font-size: 25px !important;
  }

  .content-imagecard,
  .content-appcontent {
    padding-left: 0%;
  }

  .home-work-container {
    margin-top: 0%;
  }
}

@media only screen and (max-width: 991px) {
  .margin-left-0-991px {
    margin-left: 0px !important;
  }
  .homeChoose-width {
    width: 100%;
  }

  .home-choose-back-img {
    display: none;
  }

  .home-work-desc {
    width: 53%;
  }

  .feature {
    width: 59%;
  }
  /* .home-people-title {
      width: 63%;
    } */

  /* .home-count-desc{
      margin-right: 67px;
    } */

  .home-safety-card-title-width {
    width: 139%;
  }

  /* .join-points{
      margin-right: 114px !important;
    } */

  .stylist-cng-subimg-1 {
    left: 4% !important;
    top: 55% !important;
  }
  .stylist-cng-subimg-1 > div {
    overflow-y: hidden;
  }

  /* .stylist-cng-subimg-1 > div > div{
      height: 60px !important;
      
    } */

  .home-join-now {
    float: right;
  }

  .featere-desc {
    width: 100%;
  }
}

@media only screen and (max-width: 1120px) {
  /* .home-dot-img{
      bottom: 24px;
    } */
  /* .home-chart-img{
      top: -1%;
    } */
}

@media only screen and (max-width: 960px) {
  /* .home-dot-img{
      bottom: 110px;
    } */
  /* .home-woman {
      top: 39%;
      left : 22%
    } */
  /* .home-man{
      right: -12px;
      top: 35%;
    } */
  /* .home-cng-subimg{
      top: 15%;
      right: 21%;
    } */
}

@media only screen and (max-width: 960px) {
  /* .home-look-title{
      font-size: 35px;
    } */
  .faq-icon-button {
    left: 79%;
  }
}
@media only screen and (max-width: 767px) {
  .faq-icon-button {
    left: 0%;
    bottom: -2px;
  }
  .home-safety-card-title-width {
    width: auto;
  }
  .w-121.responsive-faq {
    width: 100% !important;
  }
}

@media only screen and (max-width: 425px) {
  .safety-desc-text {
    padding-right: 0px !important;
  }
  .w-100-991-common {
    max-width: 100% !important;
  }
  .w-100-425 {
    width: 100% !important;
  }
  .join-points {
    margin-right: 0px !important;
    text-align: center;
  }
  /* .home-safety-card-title {
      font-size: 36px !important;
      line-height: 60px !important;
    } */
  /* .home-dot-img{
      bottom: 6px;
      left: 57px;
      width: 55%;
    } */
  /* .home-woman {
      left : 20%
    } */
  /* .home-man{
      right: 10px;
    } */
  /* .home-cng-subimg{
      top: -4%;
    } */
  /* .home-chart-img{
      top: -14%;
    } */
  .home-count-desc {
    margin-left: 1px;
    width: 100%;
  }

  .w-121.responsive {
    width: 147% !important;
  }
}

@media only screen and (max-width: 991px) {
  .count-top-space {
    padding-top: 50px !important;
  }
  .padding-y-0-768 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .contact-us-card {
    position: relative;
    bottom: 100px !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .home-dot-people-img2 {
    margin-top: 86px !important;
    /* top: 629%; */
    left: -80px !important;
  }
  .contact-us-card {
    position: relative;
    bottom: 70px !important;
  }
}
@media only screen and (max-width: 768px) {
  .about-meet-descs {
    max-width: 100% !important;
  }

  .contact-centered {
    top: 18% !important;
  }
  .contact-us-card {
    position: relative;
    bottom: 0px !important;
    top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-centered {
    top: 15% !important;
    width: 100% !important;
  }
  .contact-centered > span {
    font-size: 32px !important;
  }
}
.f.home-dot-people-img-2 {
  padding-bottom: 100px;
}
.home-faq-heading-button-padding {
  padding: 0px 7px;
}

.stylist-stylist-header-title {
  font-size: 48px;
  font-weight: 600;
  line-height: 50px;
}

.stylist-header-form-title {
  font-weight: 700;
  font-size: 22px;
}

.stylist-header-form-reg-btn {
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  color: black;
}

.stylist-header-form-reg-btn:hover {
  text-decoration: none;
  color: black;
}

.stylist-form > label {
  font-size: 12px;
  font-weight: 700;
}

.stylist-form > input {
  font-size: 14px;
  color: #000;
}

.stylist-form > input:focus,
.stylist-form > input:active {
  box-shadow: none;
  outline: none;
  border: 1px solid rgba(0, 0, 0);
}


@media only screen and (max-width: 1368px) {
  .stylist-cng-subimg {
    padding-top: 90px;
    right: 1px;
  }
}

.stylist-cng-subimg {
  top: 90;
  right: 0%;
}

.stylist-cng-subimg > div {
  background-color: rgba(250, 250, 250, 0.6);
  top: 90;
  border-radius: 18px;
  -webkit-animation: cngUpdown 2s linear infinite;
          animation: cngUpdown 2s linear infinite;
}

.stylist-cng-subimg > div > div {
  width: auto;
  height: 60px;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  border-radius: 15px;
}

.stylist-cng-subimg > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.stylist-cng-subimg > div > div > div > img {
  width: 80%;
}

/* --------------------- */
.stylist-cng-subimg-1 {
  /* padding-top: 130px; */
  left: 40%;
  top: 12%;
}

.stylist-cng-subimg-3 {
  top: 12% !important;
}
/* .stylist-feature{
  width: 118%;
} */

.stylist-cng-subimg-2 {
  top: 24% !important;
  left: 4% !important;
}

.stylist-cng-subimg-1 > div {
  background-color: rgba(250, 250, 250, 0.6);
  /* top: 90%; */
  border-radius: 18px;
  -webkit-animation: cngUpdown 2s linear infinite;
          animation: cngUpdown 2s linear infinite;
}

.stylist-cng-subimg-1 > div > div {
  width: auto;
  height: 60px;
  background-color: rgb(255, 255, 255);
  /* bottom: 0; */
  border-radius: 15px;
}

.stylist-cng-subimg-1 > div > div > span {
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
}

.stylist-cng-subimg-1 > div > div > div > img {
  width: 80%;
}

.stylist-cng-subimg-details-1 > span:nth-child(1) {
  font-size: 10px;
  font-weight: 400;
}

.stylist-cng-subimg-details-1 > span:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
}

/* stylist-checkbox */

.stylist-checkbox {
  position: absolute;
  opacity: 0;
}

.stylist-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.stylist-checkbox + label:before {
  content: "";
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #000;
  border-radius: 5px;
}

.stylist-checkbox:hover + label:before {
  background: #000;
}

.stylist-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.stylist-checkbox:checked + label:before {
  background: #000;
}

.stylist-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.stylist-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.stylist-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.stylist-form-signin > div {
  font-size: 13px;
}

.stylist-form-signin > div > a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.stylist-form-chk-desc {
  font-size: 12px;
}

.stylist-form-chk-desc > span:nth-child(1),
.stylist-form-chk-desc > span:nth-child(2) {
  font-size: 13px;
  font-weight: 800;
}

.stylist-join-content-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 48px;
  color: #373F41;
}

.stylist-join-content-desc {
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  color: #6b6b6b;
}

.stylist-faq-content {
  border-radius: 15px;
}

.stylist-cng-subimg-details > span:nth-child(1) {
  font-size: 10px;
  font-weight: 400;
}

.stylist-cng-subimg-details > span:nth-child(2) {
  font-size: 12px;
  font-weight: 700;
}

.stylist-customer-desc {
  font-weight: 500;
  font-size: 18px;
  line-height: 39px;
}

.stylist-main-desc {
  line-height: 32px;
  font-size: 16px;
  color: #31312e;
  font-weight: 400;
}

.stylist-anim-content {
  width: 40px;
  height: 40px;
  background-color: #000;
}

.stylist-form-btn-text {
  font-size: 12px;
}

.stylist-customer-imgs {
  width: 18vw;
}

.slider-content {
  box-shadow: 0px 52px 54px rgba(65, 62, 101, 0.102519);
  border-radius: 10px;
}

.image-1 {
  border-radius: 4%;
}

.slider-desc-text {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 30px;
  width: 75%;
}

.slider-name-text {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.slider-post-text {
  font-weight: 450;
  font-size: 16px;
  line-height: 28px;
  color: #696871;
}

.image-1 {
  position: relative;
}

.stylist-slider-fadeIn {
  -webkit-animation: slidetText 0.1s;
          animation: slidetText 0.1s;
}

@-webkit-keyframes slidetText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slidetText {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slider-previous-btn {
  width: 80px;
  height: 80px;
  border: none;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: 99%;
  left: -11%;
  font-size: 20px;
}

:hover.slider-previous-btn {
  background-color: black;
  color: white;
}

.shape-image {
  width: 60%;
}

.slider-next-btn {
  width: 16vw;
  height: 80px;
  border: none;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  top: -8%;
  right: -10%;
  font-size: 20px;
}

:hover.slider-next-btn {
  background-color: black;
  color: white;
}

@media only screen and (max-width: 769px) {
  /* .stylist-feature {
    width: 60%;
  } */
  .home-join-icons.stylist{
    justify-content: center!important;
  }
  .icon-div-stylist{
    right: 7px;
  }
}

@media only screen and (max-width: 1025px) {
  /* .stylist-cng-subimg-1 {
    padding-top: 130px;
    right: 1%;
  } */

  .stylist-cng-subimg {
    padding-top: 10px;
    right: -20px;
  }

  .stylist-cng-subimg-1 > div {
    background-color: rgba(250, 250, 250, 0.6);
    top: 0%;
    border-radius: 18px;
    -webkit-animation: cngUpdown 2s linear infinite;
            animation: cngUpdown 2s linear infinite;
    padding: 8px;
    /* height:50px; */
  }
}

@media only screen and (min-width: 992px) {
  .slider-previous-btn {
    width: 80px;
    border: none;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 39%;
    left: -9%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  :hover.slider-previous-btn {
    background-color: black;
    color: white;
  }

  .slider-next-btn {
    width: 80px;
    border: none;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 39%;
    right: -25%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  :hover.slider-next-btn {
    background-color: black;
    color: white;
  }
}

.carousel-indicators {
  display: none !important;
}

.carousel-control-prev {
  width: 14% !important;
  left: -55px !important;
  opacity: 1 !important;
}

.carousel-control-next {
  width: 14% !important;
  right: -55px !important;
  opacity: 1 !important;
}

/* .carousel-control-prev-icon { */
/* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e") !important; */

/* } */

.slider-icons-prev-next {
  width: 65px;
  height: 65px;
  background-color: #fff;
  border-radius: 50%;
  color: #000;
  transition: 0.5s;
}

.slider-icons-prev-next:hover {
  background-color: #000;
  color: #fff;
}

@media screen and (max-width: 768px) {
  

  .shape-image {
    width: 100%;
  }

  .carousel-control-prev {
    width: 14% !important;
    left: -15px !important;
    opacity: 1 !important;
  }

  .carousel-control-next {
    width: 14% !important;
    right: -15px !important;
    opacity: 1 !important;
  }

  .slider-desc-text {
    width: 100%;
  }

  .slider-previous-btn {
    top: 39%;
    left: 5%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  .slider-next-btn {
    top: 39%;
    right: 5%;
    cursor: pointer;
    transition-duration: 0.5s;
  }

  

  .slider-next-btn {
    top: 0%;
    transition-duration: 0.5s;
    height: 60px;
    width: 61px;
  }

  .slider-previous-btn {
    top: 102%;
    transition-duration: 0.5s;
    height: 60px;
    width: 61px;
  }

  .testimonials {
    max-height: 700px;
  }

  .icon-overlay {
    height: 300px;
    top: calc(50% - 150px);
  }

  .carousel-controls .control {
    width: 25px;
    height: 25px;
    top: inherit;
  }

  .prev {
    left: 0;
  }

  .next {
    right: 0;
  }

  .control i {
    font-size: 0.7rem;
  }

  .testimonials .message {
    font-size: 1rem;
  }

  .testimonials h2 {
    font-size: 1.5rem;
  }

  .stylist-cng-subimg {
    display: none;
  }

  /* .stylist-cng-subimg-1 {
    display: none;
  } */
}
@media only screen and(min-width: 1024px) and (max-width: 1439px) {
  .stylist-join-content-title {
    font-size: 24px !important;
   }
  }
  
  @media only screen and(min-width: 768px) and (max-width: 1023px) {
    .slider-icons-prev-next {
      width: 60px;
      height: 40px;
      border-radius: 50%;
      color: #000;
    }
    .stylist-join-content-title {
      font-size: 18px !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .width-100-on-991{
      max-width: 100% !important;
    }
    .slider-image-991{
      height: 300px;
      width: 100%;
      padding-bottom: 20px;
      object-fit: none;
    }
  } 
  @media only screen and (min-width:768) and (max-width: 991px) {
    .stylist-join-content-title {
      font-size: 22px !important;
    }
    
  }

/* ---------------------------------------------------- */

.form-control:focus {
  box-shadow: none !important;
  outline: none !important;
  border-right: none !important;
}

.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.faq-img > img {
  z-index: 1;
  width: 150px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}

.faq-img-title {
  font-weight: 700;
  font-size: 18px;
}

.faq-card {
  transition: 0.5s all;
  box-shadow: 0 0.1rem 3rem rgba(0, 0, 0, 0.06) !important;
  cursor: pointer;
  border: 1px solid grey;
}

.faq-card:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.faq-active {
  transition: 15s all;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.faq-fadeIn {
  -webkit-animation: opac 0.8s;
          animation: opac 0.8s;
}

.faq-serach-input {
  border-top-left-radius: 78px !important;
  border-bottom-left-radius: 78px !important;
  border: 1px solid #e0e0e0;
}

.faq-serach-input:focus,
.faq-serach-input:active {
  outline: none !important;
  box-shadow: none !important;
}

.faq-search-button {
  background-color: #fff !important;
  border: 1px solid #e0e0e0 !important;
  border-top-right-radius: 78px !important;
  border-bottom-right-radius: 78px !important;
}

.faq-card-heading {
  font-size: 22px;
  line-height: 48px;
  font-weight: 600;
}

.faq-card-desc {
  font-size: 14px;
  line-height: 28px;
}
.faq-extand-title{
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

@-webkit-keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes opac {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 992px){
  .faq-card {
    padding: 0px 60px;
  }
}
.contact-content {
  position: relative;
  text-align: center;
  color: white;
  z-index: -1;
}

.contact-centered {
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.contact-centered > span {
  font-size: 48px;
  font-weight: 600;
}

.contact-card-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 600;
}

.contact-card-desc {
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  color: #6b6b6b;
}

.contact-card-btn {
  background-color: #000 !important;
  font-size: 11px !important;
  color: #fff;
  border-radius: 50px !important;
}

.contact-card {
  border-radius: 20px;
}

.contact-create-account {
  text-align: center;
  font-weight: 600;
  font-size: 48px;
  line-height: 64px;
  /* margin-top: 15%; */
}

.contact-desc {
  text-align: center;
  font-size: 15px;
  line-height: 32px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #6b6b6b;
  letter-spacing: 0.5px;
}
.contact-first-name,
.contact-Last-name,
.contact-email-name,
.contact-contact-name,
.contact-help-name {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.form-pass-content > input {
  width: 100%;
  height: 60px;
  border: 1px solid #e2e2e2 !important;
  border-right: none !important;
  outline: none;
  border-radius: 5px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.form-icon {
  border: 1px solid #e2e2e2 !important;
  border-left: none !important;
}

.form-pass-content > input:focus,
.form-pass-content > input:active {
  border: 1px solid #e2e2e2;
  border-right: none !important;
}

.contact-first-input:focus,
.contact-help-input:focus,
.contact-Last-input:focus {
  border: 1px solid #e2e2e2 !important;
}

.contact-first-input,
.contact-Last-input {
  width: 100%;
  height: 60px;
  border: 1px solid #e2e2e2;
  outline: none;
  border-radius: 5px;
}
.contact-help-input {
  width: 100%;
  height: 280px;
  border: 1px solid #e2e2e2;
  outline: none;
  border-radius: 5px;
}

.contact-agree-name {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #31312e;
}
.contact-submit-btn {
  margin-top: 25px;
  width: 221px;
  height: 58px;
  font-size: 14px;
  line-height: 18px;
  background: #000000;
  color: #ffffff;
  border-radius: 50px;
  outline: none;
  border: none;
}

.contact-bg-img {
  width: 100%;
  height: 690px;
}

/* checkbox  */

.stylist-checkbox {
  position: absolute;
  opacity: 0;
}
.stylist-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.stylist-checkbox + label:before {
  content: "";
  margin-right: 20px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  border: 1px solid #000;
  border-radius: 5px;
}
.stylist-checkbox:hover + label:before {
  background: #fff;
}
.stylist-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px #fff(0, 0, 0, 0.12);
}
.stylist-checkbox:checked + label:before {
  background: #fff;
}
.stylist-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.stylist-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.stylist-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 10px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #000, 4px 0 0 #000, 4px -2px 0 #000, 4px -4px 0 #000,
    4px -6px 0 #000, 4px -8px 0 #000;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.contact-form-container {
  margin-bottom: 10%;
}

.contact-card-content {
  margin-top: -10%;
}



@media only screen and (min-width: 992px) {
  .contact-submit-btn {
    margin-top: 0px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 992px) {
  .contact-bg-img {
    width: 1000px;
  }
  .contact-main-title {
    color: rgba(255, 255, 255);
  }
}

.contact-us-card{
  position: relative;
  bottom: 135px;
}
* {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
  margin: 0px;
}
.over-x-unset {
  overflow-x: unset;
}
.navbar-container {
  max-width: 1140px;
  margin: 0px auto 0px auto;
  /* padding: 15px !important; */
}
.navbar-container-1 {
  max-width: 1140px;
  margin: 0px auto 0px auto;
  /* padding: 15px 30px !important; */
}
.navbar-light.header-scroll-takeover {
  background: #fbfbfb !important;
}
.navbar {
  padding: 15px;
}
.navbar-1 {
  padding: 15px 30px !important;
}
.navbar-dark.header-scroll-takeover {
  background: #000 !important;
}
body {
  background-color: #fbfbfb;
  /* background-color: #FFFFFF; */
}
.form-control {
  color: #495057;
  background-color: #fbfbfb !important;
  height: 60px;
}
.text-000 {
  color: #000;
}
.w-75 {
  width: 75% !important;
}
.w-52 {
  width: 52% !important;
}
.w-60 {
  width: 60% !important;
}
.fz-48 {
  font-size: 48px !important;
}
.fz-18 {
  font-size: 18px !important;
}
.pb-28 {
  padding-bottom: 28px;
}
.mb-10 {
  margin-bottom: 46px !important;
}
.mx-w-47 {
  max-width: 47%;
}
.mx-w-60 {
  max-width: 60%;
}
.mx-w-90 {
  max-width: 99% !important;
}
.lh-40 {
  line-height: 40px !important;
}
.w-108 {
  width: 108% !important;
}
.w-121 {
  width: 121%;
}
.w-147 {
  width: 147% !important;
}
.icon-shadow {
  box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.02);
}
.navbar-light .navbar-nav .nav-link.active {
  font-weight: 600;
}
.ml-75 {
  margin-left: 75px;
}
.footer-container .text-make {
  margin-left: -94px;
}
.home-nav-signin-btn {
  font-weight: 700 !important;
  font-size: 12px;
}

.home-nav-signup-btn {
  font-weight: 700 !important;
  font-size: 12px;
  border-radius: 4em;
  border: 2px solid #000;
}

.cheader-nav-signup-btn {
  font-weight: 700 !important;
  font-size: 12px;
  border-radius: 4em;
  border: 2px solid #fff;
}

.home-header-btn {
  border: 1px solid #000;
  color: #000;
  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  border-radius: 50px;
}

.home-header-sub-btn {
  width: 90%;
}

.footer-making-text {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.footer-unique-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.65;
}

.footer-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #8c8b8b;
  opacity: 0.3;
  width: 83%;
}

.footer-instacut-text {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.16px;
  font-weight: 700;
  color: #ffffff;
}

.footer-home-text,
.footer-Stylish-text,
.footer-Safety-text,
.footer-Company-text {
  color: #ffffff;
  font-size: 15px;
  line-height: 26px;
  opacity: 0.65;
}

.footer-instacut-desc {
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.65;
  letter-spacing: 0.5px;
}

.footer-text {
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #ffffff;
}

.footer-box-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 0.5px solid #8c8b8b;
  opacity: 0.1;
  width: 80%;
}

.nav-main {
  background-color: transparent;
}

.nav-logo {
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
}

.nav-sub {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
}

/* .navbar-dark.left-384{
  left: 384px;
} */

.navbar-light .navbar-brand {
  color: #000;
  font-size: 24px;
}

.footer-container {
  background-image: url(/static/media/footer_bg.eaecffd9.png);
  z-index: -1;
}

.footer-social-icons > span {
  color: #595959 !important;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > a {
  color: #595959;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > a:hover {
  color: #fff;
  transition: 0.3s;
  cursor: pointer;
  text-decoration: none;
}

.footer-social-icons > span:hover {
  color: rgb(250, 250, 250);
  transition: 0.3s;
}

.footer-making-text {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -1.2px;
}

.footer-unique-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
  opacity: 0.65 !important;
  margin-top: 20px;
}

.footer-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 1px solid #8c8b8b;
  opacity: 0.3;
  width: 89%;
}

.footer-instacut-text {
  font-size: 28px;
  line-height: 42px;
  letter-spacing: -0.16px;
  font-weight: 700;
  color: #ffffff;
}

.footer-home-text,
.footer-Stylish-text,
.footer-Safety-text,
.footer-Company-text {
  color: #ffffff;
  font-size: 15px;
  line-height: 26px;
  opacity: 0.65 !important;
}

.footer-instacut-desc {
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  opacity: 0.65 !important;
  letter-spacing: 0.5px;
}

.footer-text {
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.5px;
  font-weight: 500;
  color: #ffffff;
}

/* .footer-text:hover {
  border-bottom:1px solid white;
} */
a:hover {
  color: #fff;
  text-decoration: underline;
}

.footer-box-underline {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 0.5px solid #8c8b8b;
  opacity: 0.1;
  width: 80%;
}

.footer-image {
  width: 100%;
}

.footer-unique-text {
  width: 85%;
}

/* .home-apps-imgs {
  width: 35% !important;
} */

.home-apps-imgs {
  width: auto;
}

.footer-links > a {
  text-decoration: none;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

/* media query */
@media only screen and (min-width: 1921px) {
  .footer-underline {
    width: 43% !important;
  }

  .home-dot-people-img2 {
    margin-top: 140px !important;
    left: 602px !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1920px) {
  .footer-underline {
    width: 57% !important;
  }
  .home-dot-people-img2 {
    margin-top: 7% !important;
    left: 15% !important;
  }
}
@media only screen and (min-width: 1601px) {
  /* .about-us-right-image {
    left: 219px !important;
    top: 59px !important;
  } */
  /* .home-dot-people-img2 {
    margin-top: 5% !important;
    left: 23% !important;
  } */
  .home-dot-people-img {
    margin-top: 7% !important;
    left: 0px !important;
  }
}

@media only screen and (max-width: 1439px) {
  .margin-0px-under-1439 {
    margin: 0px !important;
  }
  .customer-safety-bottom-space-div {
    padding-bottom: 50px !important;
  }
  .why-join-us-image {
    width: 100% !important;
    height: auto !important;
  }
  .customer-testimonials-top-space {
    margin-top: 50px !important;
  }
  .get-groomed-top-space {
    margin-top: 50px !important;
  }

  .container-for-image-card {
    margin: 50px auto 0px auto !important;
  }

  .if-your-padding {
    padding: 20px !important;
  }
  .people-container {
    padding: 20px;
  }
}

@media only screen and (min-width: 1601px) {
  .mb-10 {
    margin-bottom: 0% !important;
  }
}

@media only screen and (min-width: 1600px) {
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1599px) {
  .footer-underline {
    width: 76% !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .home-dot-people-img-2 {
    right: 0px !important;
  }
  .home-dot-people-img {
    margin-top: 12% !important;
    left: 0px !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .home-dot-people-img2 {
    left: 47px !important;
    margin-top: 140px;
  }
}
@media only screen and (min-width: 1024px) {
  .padding-for-home-page-button {
    padding: 0px 100px 0px 7px;
  }
  .display-none-on-big-screen {
    display: none;
  }
}
@media only screen and (max-width: 1023px) {
  .mb-10 {
    margin-bottom: 100px !important;
  }

  .faq-card-row-top-space {
    margin-top: 55px;
    padding: 20px;
  }
  .faq-search-bar-height {
    width: 98%;
  }
  .slider-post-text {
    padding: 0px 0px 20px 0px !important;
  }
  .COVID-19-Door-to-Door-Safety-content-padding {
    padding: 0px 20px 50px 20px !important;
  }
  .overflow-xunset-under-1023 {
    overflow-x: unset;
  }
  /* .header-container {
    padding: 15px 15px !important;
  } */
  .counting-border-bottom-padding {
    padding: 50px 0px 50px 50px !important;
  }
  .customer-safety-image {
    width: 100% !important;
  }
  .orders-left-space {
    margin-left: 5px !important;
  }
  .my-display-hidden {
    display: none;
  }
  .about-any-desc-text {
    font-size: 22px !important;
    line-height: 42px !important;
  }
  .dont-be-stranger-padding {
    padding: 27px 15px 49px 20px !important;
  }

  .with-lots-of-unique-padding {
    padding: 25px 10px 51px 10px !important;
  }
  .about-box-work {
    padding: 50px 30px 50px 30px !important;
  }

  .about-us-top-image {
    width: 100% !important;
    height: auto !important;
  }
  .about-us-lorm-ispum-padding {
    padding: 14px 10px 20px 10px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-top: 20px !important;
  }

  .stylist-with-carry-third-image {
    width: 100% !important;
  }

  .safety-desc-text {
    padding-right: 0px !important;
  }
  .safety-containerr-inner-padding {
    padding: 20px !important;
    margin: 0px;
  }
  .stylis-last-btn-align-right {
    margin: 50px 0px 50px auto !important;
  }
  .stylist-faq-lorem-text-padding {
    padding: 20px 20px 20px 20px !important;
  }
  .stylist-love-slider-width {
    width: 100% !important;
    height: auto !important;
    margin: 0px !important;
  }
  .padding-for-text-below-milestone {
    padding: 0px 0px 0px 0px !important;
  }
  .miled-stone-top-space {
    margin-top: 50px !important;
    padding: 0px 20px;
  }

  .right-10px-under-768 {
    right: 10px !important;
    margin-top: 64px !important;
  }
  .join-points {
    margin-right: 0px !important;
    margin-top: 24px;
  }

  .width-100-under-768 {
    width: 100% !important;
  }
  .home-work-desc {
    width: 100%;
  }
  .feature {
    width: 100%;
  }
}

.safety .home-work-desc.These-are-the {
  padding: 15px 0 15px 0;
}

@media only screen and (max-width: 767px) {
  .container-for-image-card {
    margin: 100px auto 0px auto !important;
  }
  .padding-for-footer-on-mobile {
    padding: 0px 20px !important;
  }
  .padding-zer-for-footer-on-mobile {
    padding: 0px !important;
  }
  .chhose-book-and-get-container-faq {
    margin: 50px auto 0px auto !important;
  }
  .faq-extand-title {
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .safety-top-sapce {
    margin-top: 20px !important;
  }
  .home-work-desc {
    font-size: 16px !important;
  }
  .home-header-sub-btn {
    width: 100% !important;
  }
  .margin-top-60px-for-row-stylist {
    margin-top: 0px !important;
  }
  .stylist-page-number-top-space {
    margin-top: 50px;
  }
  .take-on-new-top-space {
    margin-top: 0px !important;
    padding: 20px;
  }
  .customer-way-text {
    font-size: 18px !important;
  }
  .signup-crreate-account {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .signup-desc {
    padding: 10px;
  }
  .about-text-1 {
    font-size: 18px !important;
  }

  .about-work-text {
    font-size: 32px !important;
  }
  .about-font {
    font-size: 32px !important;
  }
  .contact-create-account {
    font-size: 32px !important;
  }
  .font-size-32px-under-767 {
    font-size: 32px !important;
  }
  .safety-image1-heading {
    font-size: 18px !important;
  }
  .home-choose-headings {
    font-size: 18px !important;
  }
  .margin-top-130px {
    margin-top: 130px !important;
  }
  .home-work-title2 {
    font-size: 32px !important;
  }
  .slider-icons-prev-next {
    width: 40px !important;
    height: 40px !important;
  }
  .home-book-your-Button {
    margin: 0px auto !important;
  }
  /* .home-header-btn {
    margin: 15px auto !important;
  } */
  .chhose-book-and-get-container {
    margin: 100px auto 0px auto !important;
    width: 98%;
    /* text-align: center; */
  }
  .padding-0px-under-767 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .margin-top20px-under-767px {
    margin-top: 20px;
  }
  .padding-horizantly-10px-767px {
    padding: 0px 10px;
  }

  .home-work-title {
    font-size: 32px !important;
  }
  .safety-top-sapce {
    padding: 10px;
  }
  .margin-top-100-under-767px {
    margin: 130px auto 50px auto !important;
  }
  .here-what-text-top-psace {
    margin: 20px 0px 50px 0px !important;
  }
  .stylist-cng-subimg-2 {
    top: 59% !important;
    left: 6% !important;
  }

  .hhelping-image-div {
    margin-top: 10px;
  }
  .faq-customer-text-top-space-under-425 {
    margin-top: 50px;
  }
  .frequently-asked-space {
    padding: 20px 0px 50px 0px !important;
  }
  .have-any-que-main-div-space {
    margin: 130px 0px 50px 0px !important;
    padding: 50px 0px;
  }
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .intrsted-to-join-top-space {
    margin-top: 10% !important;
  }
  .dedicated-row-top-space {
    padding: 20px 0px 0px 0px !important;
  }
  .with-lots-of {
    padding: 20px 10px 20px 10px !important;
  }
  .work-around-main-div-top-and-bottom-space {
    margin: 50px 0px 50px 0px;
  }
  .safety-and-constantly-paddinf {
    padding: 0px 0px 0px 0px !important;
  }
  .safety-helping-image {
    right: 1px !important;
  }

  .btn-stylis-width {
    width: 100% !important;
    margin: 0px 0px 0px 20px;
  }
  .stylist-iamge {
    height: auto !important;
    padding-bottom: 50px;
  }
  .chhose-book-and-get-container-text {
    padding: 50px 0px 0px 0px !important;
  }
  /* .chhose-book-and-get-image-container {
    height: auto !important;
  } */
}

@media only screen and (max-width: 374px) {
  .home_work_lines {
    bottom: 30% !important;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .hhelping-image-div {
    height: 270px !important;
  }
  .stylist-with-carry-third-image {
    height: 109px !important;
    margin-top: 110px !important;
  }
  .safety-helping-image {
    width: 81px !important;
    height: 93px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: 297px !important;
    object-fit: cover !important;
  }
  .install-our-app-div {
    margin-top: 30px !important;
  }
  .home-nav-signup-btn {
    font-size: 8px !important;
    margin: 1px 0px 0px 0px !important;
  }
  .burger-menue {
    height: 25px !important;
    margin-top: 10px !important;
  }
  .home-nav-signup-btn {
    font-size: 6px !important;
  }
  .footer-text {
    font-size: 9px !important;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 108px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }
  .home-dot-people-img-2 {
    -webkit-transform: translateY(-93%) !important;
            transform: translateY(-93%) !important;
  }
  .stylist-with-carry-second-image {
    width: 160px !important;
    height: 210px !important;
  }

  .home-count {
    font-size: 30px !important;
  }
  .fz-11-on-320 {
    font-size: 11px !important;
  }
  .stylist-form-chk-desc {
    font-size: 10px;
  }
  .padding-left-for-stylist-checkbox {
    padding-left: 15px;
  }
  .home-dot-img {
    bottom: 0px;
    left: 13%;
  }
  .home-chart-img {
    position: relative;
    left: 137px;
    top: 28px;
  }
  .home-woman {
    top: 45% !important;
    left: 16% !important;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 18% !important;
    width: 24%;
  }
  .home-man {
    right: -3px !important;
    top: 33% !important;
  }
  .padding-7px-under-320 {
    padding: 7px 5px !important;
  }
  .image-height-home-page {
    height: 300px !important;
  }
}
@media only screen and (max-width: 424px) {
  .covid-19-door-top-div-space {
    margin-top: 120px !important;
  }
  .safety-is-our-key-priority-top-space {
    margin-top: 130px !important;
  }
  .safety-protocol-top-space {
    margin-top: 90px !important;
  }
  /* .nav-logo {
      font-size: 18px !important;
    } */
  /* .home-safety-card-title {
      font-size: 28px !important;
      line-height: 40px !important;
    } */
  /* .home-work-title2 {
      font-size: 28px !important;
    } */
  .home-choose-desc {
    font-size: 16px !important;
  }
  /* .home-people-title {
      font-size: 28px !important;
  } */
  .home-dot-people-img-2 {
    right: 0px !important;
    height: 100px !important;
    overflow-y: hidden;
  }

  /* .home-work-title {
      font-size: 32px !important;
    } */

  .building-a-safer-text-padding {
    padding: 0px 20px 0px 20px !important;
  }
  .k50-conatiner-padding {
    padding: 20px !important;
  }
  .Heres-an-right-padding {
    padding: 0px !important;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .home_work_lines {
    bottom: 37% !important;
  }
  .hhelping-image-div {
    height: 300px !important;
  }
  .stylist-with-carry-third-image {
    height: 114px !important;
    margin-top: 98px !important;
  }
  .safety-helping-image {
    width: 87px !important;
    height: 92px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
    /* object-fit: cover !important; */
  }
  .install-our-app-div {
    margin-top: 38px !important;
  }
  .install-our-app-div {
    height: 110px !important;
  }
  .home-nav-signup-btn {
    font-size: 8px !important;
    margin: 1px 0px 0px 0px !important;
  }
  .burger-menue {
    height: 25px !important;
    margin-top: 11px !important;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 48px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }
  .home-conatiner-mobile-height {
    height: 347px !important;
  }
  .home-man {
    right: -2px !important;
    top: 37% !important;
  }
  .home-cng-subimg {
    top: 2% !important;
    right: 22% !important;
  }
  .home-woman {
    top: 41% !important;
    left: 14% !important;
  }
  .stylist-with-carry-second-image {
    width: 214px !important;
    height: 220px !important;
  }

  .padding-left-for-stylist-checkbox {
    padding-left: 16px;
  }
  .home-dot-img {
    bottom: 12px;
    left: 12%;
  }
  .home-chart-img {
    left: 177px !important;
    width: 100% !important;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .home-dotted-line-bottom {
    bottom: 39% !important;
  }
  .home_work_lines {
    bottom: 39% !important;
  }
  .hhelping-image-div {
    height: 300px !important;
  }
  .stylist-with-carry-third-image {
    height: 137px !important;
    margin-top: 110px !important;
  }
  .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
    /* object-fit: cover !important; */
  }
  .install-our-app-div {
    height: 130px !important;
  }
  .home-nav-signin-btn {
    margin-top: 10px;
  }
  .image-height-home-page {
    height: 398px !important;
  }
  .carousel-control-prev {
    left: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .carousel-control-next {
    right: 3px !important;
    bottom: 14px !important;
    padding: 4px;
  }
  .height-foraboust-us-icon-2 {
    height: 155px !important;
    width: 135px !important;
    left: 48px !important;
    top: 25px !important;
  }
  .height-foraboust-us-icon-1 {
    height: 155px !important;
    width: 135px !important;
    right: 36px !important;
  }

  .home-dot-people-img-2 {
    -webkit-transform: translateY(-85%) !important;
            transform: translateY(-85%) !important;
    right: 0px !important;
  }
  .install-our-app-div {
    margin-top: 43px !important;
  }

  .stylist-with-carry-second-image {
    width: 230px !important;
    height: 240px !important;
  }
  .building-a-safer-text-padding {
    padding: 0px 30px 30px 30px !important;
  }
  .padding-left-for-stylist-checkbox {
    padding-left: 16px;
  }

  .home-dot-img {
    bottom: 40px;
    left: 10%;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 25%;
  }
  /* .home-look-title {
    font-size: 36px !important;
  } */
  .home-man {
    top: 29% !important;
    right: 8px !important;
  }
  .home-woman {
    top: 38% !important;
    left: 13% !important;
  }
  .home-chart-img {
    left: 198px !important;
    width: 100% !important;
  }
}
@media only screen and (min-width: 768px) {
  .footer-home-text,
  .footer-Stylish-text,
  .footer-Safety-text,
  .footer-Company-text {
    margin-bottom: 20px;
  }

  .padding-left-and-right-zero-for-home-page {
    padding: 0px !important;
  }

  .faq-faq-button-padding {
    padding: 0px 30px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .padding-for-home-faq {
    padding: 0px 30px 0px 20px !important;
  }
  .home_work_lines {
    bottom: 30%;
  }
  .padding-left-and-right-zero-on-stylist {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .padding-for-home-page-button {
    padding: 0px 0px 0px 10px;
  }
  .navbar-1 {
    padding: 15px 60px !important;
  }
  .navbar-container-1 {
    padding: 15px 60px !important;
  }
  .here-top-space {
    margin-top: 0px !important;
  }
  .modal-content {
    padding: 20px !important;
    width: 50% !important;
  }

  .setisfaction-text-bottom-space {
    margin-bottom: 50px !important;
  }
  .quality-service-image {
    width: 100% !important;
  }
  .joe-bridges-photo {
    width: 100% !important;
    height: 300px !important;
    object-fit: cover;
  }
  .footer-text {
    font-size: 10px;
  }
  .flexibility-heading-top-space {
    margin-top: 20px !important;
  }
  .fz-26px-flexibility {
    font-size: 23px !important;
  }
  .home-safety-card-title {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  /* .we-are-commited-image {
    width: 100% !important;
    height: auto !important;
} */
  .we-are-commited-image {
    width: 100% !important;
    height: 360px !important;
    object-fit: cover !important;
  }
  .padding-top-for-20px-safety-image {
    padding: 20px 0px 0px 0px;
  }
  .building-a-safer-padding {
    padding: 0px 20px 50px 20px !important;
  }
  .we-also-have-a-greate-padding {
    padding: 130px 20px 34px 20px !important;
  }
  .why-you-should-join-us-top-space {
    margin: 130px 0px 22px 0px !important;
  }
  .stylist-top-space {
    padding: 0px 15px !important;
  }
  .sidenav .closebtn {
    right: 70px !important;
  }
  .we-are-commited-right-padding {
    padding-right: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .home-feature-tag {
    font-size: 30px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 20px !important;
    padding-top: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }

  .work-around-image-2 {
    height: 400px !important;
    /* object-fit: cover; */
    margin-top: 20px;
  }
  .work-around-image-1 {
    height: 350px !important;
    /* object-fit: cover; */
  }
  /* .quality-service-image {
    width: 346px !important;
  } */
  .faq-card-height {
    height: 60px !important;
    overflow-y: hidden !important;
  }
  .collapse-button-faq {
    height: 30px !important;
  }
  .faq-fz-12 {
    font-size: 12px;
  }

  .home-app-image2 {
    -webkit-transform: translate(-76%, -18%) !important;
            transform: translate(-76%, -18%) !important;
    top: 70px !important;
    overflow-y: hidden !important;
    height: 124px !important;
  }
  .card-height-people {
    height: 400px !important;
  }
  .home-dot-people-img-2 {
    -webkit-transform: translateY(-90%) !important;
            transform: translateY(-90%) !important;
    right: 0px !important;
  }
  .home-look-title {
    font-size: 27px !important;
  }
  .here-to-help-you {
    margin-top: 200px !important;
  }
  .safety-helping-image {
    right: 1px !important;
  }
  .stylist-with-carry-second-image {
    width: 270px !important;
  }
  .we-also-have-image-size {
    width: 100% !important;
    height: auto !important;
    margin: 50px;
  }
  .stylist-iamge {
    height: 416px !important;
  }
  .fz-9-1024 {
    font-size: 9px !important;
    padding-top: 10px !important;
  }
  .btn-stylis-width {
    width: 96px !important;
    margin-left: 12px !important;
  }
  .checkbox-left-space-stylist {
    margin-left: 10px !important;
  }
  .Heres-an-right-padding {
    padding: 4px 0px 53px 0px !important;
  }
  .image-height-home-page {
    height: 400px !important;
  }
  .home-dot-img {
    bottom: 98px;
    left: 7%;
  }
  .padding-for-flexibility {
    padding: 50px 200px 0px 200px !important;
  }
  .home-count {
    font-size: 22px;
  }
  .For-a-limited-top-space {
    margin-top: 0px !important;
  }

  .your-safety-text-left-space {
    margin-left: 20px !important;
  }
  .We-have-combined {
    padding: 22px 80px 143px 80px !important;
  }

  .get-groomed-text-padding {
    height: 500px !important;
    padding: 0px 0px 0px 48px !important;
  }
  .we-are-commited-top-over-div {
    padding: 50px !important;
  }
  .width-100-on-768 {
    width: 100% !important;
  }
  .qulaity-text-left-sppace {
    margin-left: 0px !important;
  }
  .padding-left-20px-on-768 {
    padding-left: 20px !important;
  }
  .chhose-book-and-get-container-text {
    padding: 0px !important;
  }
  /* .chhose-book-and-get-image-container {
    height: auto !important;
} */
  .home-man {
    right: 25px;
    top: 21%;
  }
  .home-cng-subimg {
    top: 2%;
    right: 29%;
    height: 68px;
    width: 69px;
    overflow-y: hidden;
  }
  .home-woman {
    top: 29%;
    left: 10%;
  }
  .home-chart-img {
    left: 159px !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
  .we-also-have-image-size {
    width: 431px !important;
  }
  .we-are-commited-image {
    width: 470px !important;
  }
  .count-top-space {
    padding-top: 50px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .home-feature-tag {
    font-size: 30px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 20px !important;
    padding-top: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }
  .counting-border-bottom-padding {
    padding: 50px !important;
  }
  .mx-w-90 {
    max-width: 88% !important;
  }
  .quality-service-image {
    padding: 20px;
  }
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .faq-card-padding {
    padding: 25px 18px 0px 10px !important;
  }

  .faq-card-height {
    height: 67px !important;
    overflow-y: hidden !important;
  }
  .collapse-button-faq {
    height: auto !important;
  }
  .home-app-image2 {
    top: 36px !important;
  }
  .card-height-people {
    height: 500px !important;
  }
  .home-count {
    font-size: 36px !important;
  }
  .home-dot-people-img-2 {
    -webkit-transform: translateY(-91%) !important;
            transform: translateY(-91%) !important;
    right: 0px !important;
  }
  .home-dot-people-img {
    margin-top: 14% !important;
  }
  .home-look-title {
    font-size: 43px !important;
  }
  .home-man {
    right: -2px !important;
    top: 27% !important;
  }
  .home-cng-subimg {
    top: 6% !important;
    right: 26% !important;
  }
  .home-woman {
    top: 37% !important;
    left: 16% !important;
  }
  .home-chart-img {
    left: 254px !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .left-40-for-top-notification {
    left: 36% !important;
  }
  .form-side-top-space {
    padding-top: 30px;
  }
  .stylist-top-space {
    padding: 40px;
  }
  .fz-9-1024 {
    font-size: 12px !important;
  }
  .btn-stylis-width {
    width: 116px !important;
  }
  /* .stylist-top-space {
    padding: 40px;
} */

  .padding-for-flexibility {
    padding: 50px 50px 0px 0px !important;
  }
  .We-have-combined {
    padding: 22px 210px 143px 210px !important;
  }

  .get-groomed-text-padding {
    height: 491px !important;
    padding: 0px 0px 0px 20px !important;
  }
  .stylist-cng-subimg-1 {
    left: 40%;
  }
  .chhose-book-and-get-container-text {
    padding: 20px !important;
  }
  .home-dot-img {
    bottom: 31px !important;
    left: 14% !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  /* .home_work_lines {
      bottom: 53% !important;
  } */
  .count-top-space {
    padding-top: 50px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 0px !important;
  }
  .container-for-image-card {
    padding: 20px;
  }
  .counting-border-bottom-padding {
    padding: 50px !important;
  }
  /* .quality-service-image {
      width: 103% !important;
  } */
  .dont-be-stranger-padding {
    padding: 27px 0px 49px 0px !important;
  }
  .home-app-image2 {
    -webkit-transform: translate(-48%, -35%) !important;
            transform: translate(-48%, -35%) !important;
    top: 42px !important;
  }
  .home-choose-back-img {
    margin-top: 136px !important;
    margin-left: 36px !important;
  }
  .home-dot-people-img-2 {
    -webkit-transform: translateY(-90%) !important;
            transform: translateY(-90%) !important;
  }
  .here-to-help-you {
    margin-top: 100px !important;
  }
  .btn-stylis-width {
    width: 114px !important;
  }
  .fz-10-1200 {
    font-size: 10px !important;
  }
  .padding-for-flexibility {
    padding: 50px 50px 0px 0px !important;
  }
  .chhose-book-and-get-container-text {
    padding: 20px 0px 0px 40px !important;
  }
  .home-dot-people-img2 {
    left: 37px !important;
  }
  .get-groomed-text-padding {
    height: 477px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .here-to-help-you {
    padding: 0px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding-left: 0px !important;
  }
  .faq-page-top-margin {
    margin-top: 0px !important;
  }
  .people-container {
    margin: 130px auto 0px auto !important;
    padding: 20px;
  }
  .faq-btn-top-space {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }

  .get-groomed-image {
    width: 100% !important;
    height: auto !important;
  }

  /* .We-have-combined {
    padding: 22px 20px 50px 20px  !important;
} */
  .what-we-offer-top-space {
    margin-top: 130px;
  }
  .available-to-download-top-space {
    padding: 0px 20px !important;
  }
  /* .how-it-works-text-padding {
    padding: 0px 20px !important;
} */
  /* .here-top-space {
    margin-top: 0px !important;
} */
  .home-apps-imgs {
    max-width: 60%;
  }
}

@media only screen and (max-width: 1025px) {
  .footer-unique-text {
    width: 100%;
  }
}
/* @media only screen and (min-width: 992px) {
    .some-extra-padding
  } */

@media only screen and (max-width: 992px) {
  .footer-unique-text {
    width: 100% !important;
  }

  .home-apps-imgs {
    width: 40% !important;
  }

  /* .home-header-sub-btn {
    width: 50%;
  } */
}

@media only screen and (min-width: 992px) {
  .footer-box-underline {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 1px solid #8c8b8b;
    opacity: 0.3;
    width: 20%;
    display: none;
  }

  .footer-image {
    width: 100%;
  }

  .footer-box-underline {
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 0.5px solid #8c8b8b;
    opacity: 0.3;
    width: 20%;
    display: none;
  }

  .home-header-btn {
    border: 1px solid #000;
    color: #000;
    font-size: 10px;
    font-weight: 700;
    line-height: 30px;
    border-radius: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .side-barhidden-on-largcsreen {
    display: none;
  }
  .disply-none-on-large-screen-burgure {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  .display-none-after-992px {
    display: none !important;
  }
}

@media only screen and (max-width: 991px) {
  .display-none-under-991px {
    display: none !important;
  }

  .navbar-dark .navbar-toggler {
    display: none !important;
  }

  .navbar-light .navbar-toggler {
    display: none;
  }
  .w-100-991-common {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .stylist-faq-padding {
    padding: 0px 15px 0px 15px !important;
  }
  .modal-content {
    padding: 20px !important;
    width: 80% !important;
  }
  .footer-playstore-button-width {
    margin: 22px 0px;
  }
  .footer-making-text {
    margin-bottom: 22px;
  }
  .joe-bridges-photo {
    width: 100% !important;
    height: auto !important;
    /* object-fit: none; */
  }
  .safety-page-bottom-margin {
    margin-bottom: 48px;
  }
  .safety-footer-text {
    padding-top: 30px;
  }
  .home-work-icon-title {
    margin-top: 24px;
  }
  .home-imgcard-contents {
    font-size: 14px !important;
  }
  .safety-card-bottom-space-45px {
    padding-top: 10px;
    padding-bottom: 45px;
  }
  .safety-card-top-space-45px {
    padding-top: 45px;
  }
  .home-work-desc {
    padding: 0px 20px;
  }
  .space-bw-cards {
    margin-top: 20px !important;
  }
  .home-imgcard-contents {
    margin: 24px 0px;
  }
  .we-are-commited-right-padding {
    padding-top: 45px;
  }
  .flexibility-heading-top-space {
    margin-top: 22px !important;
  }

  .fz-26px-flexibility {
    font-size: 18px !important;
  }
  .stylist-header-heading {
    font-size: 32px !important;
    line-height: 45px;
  }
  .home-safety-card-title {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .home-choose-headings2 {
    font-size: 18px !important;
  }
  .home-look-title {
    font-size: 32px !important;
  }
  .about-meet-text {
    font-size: 32px !important;
  }
  .leave-us-lorem-text-form {
    padding: 19px 10px 50px 10px !important;
  }
  .leave-us-lorem-text-form {
    margin: 19px 0px 0px 0px !important;
  }

  .padding-top-for-20px-safety-image {
    padding: 50px 0px 0px 0px;
  }
  .work-around-main-div-top-and-bottom-space {
    margin: 130px 0px 130px 0px !important;
  }
  .customer-safety-bottom-button-padding {
    padding: 22px 0px 20px 0px !important;
  }
  .building-a-safer-padding {
    padding: 0px 20px 0px 20px !important;
  }
  .k50-conatiner-padding {
    padding: 80px 80px 0px 80px !important;
  }
  .we-also-have-a-greate-padding {
    padding: 0px 20px 34px 20px !important;
  }
  .why-you-should-join-us-top-space {
    margin: 0px 0px 22px 0px !important;
  }
  .safety-image {
    margin-top: 30px !important;
  }
  .covid-safety-top-space {
    margin: 0px 0px 32px 0px !important;
  }
  .safety-protocol-top-space {
    margin-top: 130px !important;
  }
  .Heres-an-right-padding {
    padding: 4px 0px 53px 0px !important;
  }
  .covid-19-door-top-div-space {
    margin-top: 130px !important;
  }
  .covid-19-padding {
    padding: 20px !important;
  }
  .container-for-image-card {
    padding: 0px !important;
    width: 90%;
    /* text-align: center; */
  }
  .home-feature-tag {
    font-size: 18px;
    font-weight: 600;
    width: 75%;
  }
  .qulaity-services-left-padding {
    padding-right: 0px !important;
    padding-top: 0px !important;
    text-align: center;
  }
  .our-feature-top-space {
    padding-top: 0px !important;
  }
  .available-to-download-top-space {
    margin-top: 5px !important;
  }
  .how-it-works-top-space {
    margin: 5px 0px !important;
  }
  .safety-is-our-key-priority-top-space {
    margin-bottom: 100px !important;
  }
  .safetyis-our-key-priority-right-padding {
    padding: 0px 30px !important;
  }
  .safety-footer-heading {
    font-size: 32px !important;
  }
  .miled-stone-text-top-space {
    margin-top: 0px !important;
  }
  .refferal-reaward-top-space {
    margin-top: 20px !important;
  }
  /* .chhose-book-and-get-container {
    margin: 50px auto 0px auto !important;
} */
  .stylist-top-space {
    margin-top: 0px !important;
    padding: 0px 10px;
  }
  .why-choice-top-space {
    margin-top: 130px;
  }
  .width-for-people-text {
    width: 100% !important;
  }
  .your-safety-div-top-space {
    margin-top: 50px !important;
  }
  .top-margin-for-install-our-app {
    margin-top: 30px;
  }
  .work-around-image-2 {
    /* Height:300px !important; */
    /* object-fit: cover; */
    margin-top: 20px;
  }
  /* .work-around-image-1 {
    Height: 300px !important;
    object-fit: cover;
} */
  .elements-text-align-center-under-767 {
    text-align: center;
  }
  .element-center-under-767px {
    margin: 0px auto !important;
  }
  .width-90-under-767-px {
    width: 90% !important;
  }
  .width-50-for-what-cutomer-text-on-small {
    width: 50%;
  }
  .home-dot-people-img {
    margin-top: 11% !important;
  }
  .margin-0-for-center-image-smallscreen {
    margin: 0px auto !important;
  }
  .padding-for-flexibility {
    padding: 30px 15px 0px 15px !important;
  }
  .card-height-people {
    height: 400px !important;
  }
  .home-count {
    font-size: 26px !important;
  }
  .here-to-help-you {
    margin-top: 200px !important;
    padding: 0px 20px !important;
  }
  .stylist-safety-image-top-space-safety {
    margin-top: 80px;
  }
  .customer-safety-image {
    width: 100% !important;
    height: auto !important;
  }
  .we-also-have-image-size {
    width: 100% !important;
    height: auto !important;
    margin-top: 20px;
  }
  .get-groomed-text-padding {
    height: auto !important;
    padding: 50px 20px 0px 20px !important;
  }
  .why-join-us-image {
    margin-top: 50px;
  }
  .people-card-top-space {
    margin-top: 50px !important;
  }

  .we-are-commited-image {
    margin-top: 50px !important;
  }
  .we-are-commited-top-over-div {
    padding: 20px !important;
  }
  .comfot-and-easetop-space {
    margin-top: 30px !important;
  }
  .qulaity-text-left-sppace {
    margin-left: 0px !important;
  }
  .why-choose-text-top-space {
    margin-top: 100px !important;
  }
  .setisfaction-text-bottom-space {
    margin-bottom: 50px !important;
  }
  .home-people-title {
    width: 100%;
  }
  .choose-main-container-top-space {
    margin-top: 130px !important;
  }
  .your-safety-text-left-space {
    margin: 0px auto !important;
    text-align: center;
    padding: 0px 10px !important;
  }
  .We-have-combined {
    padding: 22px 20px 50px 20px !important;
  }
  .quality-service-image {
    width: 100% !important;
    height: auto !important;
    margin: 0px auto;
    text-align: center !important;
  }
  .dispaly-none-on-mobile-under-768 {
    display: none !important;
  }

  .pr-35-991 {
    padding-right: 30px !important;
  }
}
.dark-margin-auto {
  margin: 0px auto !important;
}
/* ============== */
/* ================for-header============= */

/* ==============home-content============= */
.image-height-home-page {
  height: 465px;
  overflow-y: hidden;
}
.chhose-book-and-get-container {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-faq {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-safety {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-contact-us {
  max-width: 1140px;
  margin: 0px auto 0px auto;
}
.chhose-book-and-get-container-top-space {
  margin-top: 60px !important;
}
.chhose-book-and-get-container-text {
  padding: 40px 0px 0px 70px;
}
/* .chhose-book-and-get-image-container{
  height: 584px;
} */
.here-top-space {
  margin-top: 96px;
}
.how-it-works-top-space {
  margin: 22px 0px;
}
.install-our-app-div {
  margin-top: 30px;
  overflow-y: hidden;
}
.install-our-app-icon-size {
  height: 120px;
  width: 120px;
}
.available-to-download-top-space {
  margin-top: 16px;
  padding: 0px 45px;
}
.what-we-offer-top-space {
  margin-top: 151px;
}
.our-feature-top-space {
  padding-top: 13px;
}
.We-have-combined {
  padding: 22px 250px 143px 250px;
  color: #6b6b6b;
  opacity: 0.7;
  line-height: 30px !important;
}
.quality-service-image {
  width: 450px;
  height: auto;
}
.qulaity-services-left-padding {
  padding-right: 164px;
  padding-top: 10px;
  padding-bottom: 20px;
}
.what-we-offer-container {
  max-width: 1140px;
  margin: 151px auto 0px auto;
}
.your-safety-div-top-space {
  margin-top: 108px;
}
.your-safety-text-left-space {
  margin-left: 0px;
}

.why-choose-div-top-space {
  margin-top: 134px;
}
.dot-image {
  width: 146.16px;
  height: 165.56px;
}
.dot-image-position {
  position: relative;
  top: 127px;
  right: 25px;
}
.qulaity-text-left-sppace {
  margin-left: 40px;
}
.why-choose-text-top-space {
  margin-top: 118px;
}
.comfot-and-easetop-space {
  margin-top: 81px;
}
.setisfaction-text-bottom-space {
  margin-bottom: 80px;
}
.container-for-image-card {
  max-width: 1140px;
  margin: 138px auto 0px auto !important;
}
.we-are-commited-image {
  width: 532px;
  height: 544.17px;
}
.we-are-commited-right-padding {
  padding-right: 12px;
}
.we-are-commited-top-over-div {
  padding: 60px;
}
.people-container {
  max-width: 1140px;
  margin: 150px auto 0px auto;
}
.people-card-top-space {
  margin-top: 107px;
}
.home-dot-people-img2 {
  margin-top: 140px;
  left: 126px;
}
.home-people-section-c-upper {
  border-radius: 20px !important;
  background-color: white;
}
.home-people-section-c {
  box-shadow: 0px 25px 35px rgba(0, 0, 0, 0.02);
  border-radius: 0px 0px 20px 20px !important;
}
.hover-for-card :hover {
  box-shadow: 0px 70px 80px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px !important;
}
.card-height-people {
  height: 550px;
}
.why-choice-top-space {
  margin-top: 0px;
}
.why-join-us-image {
  width: 641px;
  height: 483px;
}
.padding-for-flexibility {
  padding: 104px 16px 0px 0px;
}
.flexibility-safety-top-space {
  margin-bottom: 34px;
}
.flexibility-its-text-space-bw {
  margin-top: 10px;
}
.get-groomed-image {
  width: 85% !important;
  height: auto !important;
  margin-left: 30px;
  /* object-fit: none; */
}
.get-groomed-top-space {
  margin-top: 130px;
}
.For-a-limited-top-space {
  margin-top: 46px;
}
.get-groomed-text-padding {
  height: 500px;
  padding: 0px 0px 0px 111px;
}
.customer-testimonials-top-space {
  margin-top: 140px;
}
.customer-testimonials-top-space-for-faq {
  margin-top: 70px;
}
.frequently-asked-space {
  padding: 20px 0px 90px 0px;
}
/* .faq-card-height{
  height: 110px;
} */
.faq-card-padding {
  padding: 2% 4%;
}
.space-bw-cards {
  margin-top: 30px;
}
.faq-btn-top-space {
  margin-top: 75px;
  margin-bottom: 176px;
}
.counting-border-bottom-padding {
  padding-bottom: 113px;
}
.width-for-people-text {
  width: 584px;
}
.choose-main-container-top-space {
  margin-top: 169px;
}
.collapse-button-faq {
  height: 74px;
}
/* ===================stylist=============== */
.stylist-top-space {
  margin-top: 71px;
}
.Heres-an-right-padding {
  padding: 4px 90px 53px 0px;
}
.stylist-form-top-space {
  margin-top: 40px;
}
.fz-12 {
  font-size: 12px;
}
.btn-stylis-width {
  width: 121px;
}
.join-now-btn-top-space {
  margin-top: 24px;
}

.stylist-iamge {
  width: 561px;
  height: 471px;
}
.why-you-should-join-us-top-space {
  margin: 171px 0px 22px 0px;
}
.we-are-incredible-padding {
  padding: 0px 225px 20px 225px;
}
.flexibility-box-shadow {
  box-shadow: 0px 38.2353px 30.5882px rgba(0, 0, 0, 0.02);
}
.flexibility-heading-top-space {
  margin-top: 39px;
}
.take-on-new-top-space {
  margin-top: 20px;
}
.we-also-have-a-greate-padding {
  padding: 142px 300px 34px 300px;
}
.miled-stone-top-space {
  margin-top: 61px;
}
.refferal-reaward-top-space {
  margin-top: 76px;
}
.we-also-have-image-size {
  width: 511px;
  height: 400px;
}
.miled-stone-text-top-space {
  margin-top: 37px;
}
.padding-for-text-below-milestone {
  padding: 0px 104px 0px 0px;
}
.stylist-love-us-margin-top {
  margin-top: 142px;
}
.here-what-text-top-psace {
  margin: 29px 0px 88px 0px;
}
.stylist-love-slider-width {
  width: 920px;
  /* height: 430px; */
  margin: 0px auto;
}
.stylist-love-slider-shadow {
  box-shadow: 0px 52px 54px rgba(65, 62, 101, 0.102519);
}
.k50-conatiner-padding {
  padding: 80px;
}
.slider-lorem-text-padding {
  padding: 29px 45px;
}
.stylist-faq-collapse-space {
  margin-top: 25px;
}
.stylist-faq-lorem-text-padding {
  padding: 22px 250px 51px 250px;
}
.stylis-last-btn-align-right {
  margin: 51px 0px 96px auto;
}
.stylist-password-border-left {
  border-right: 1px solid #f8f8f8 !important;
}
/* ==================faq================ */
.faq-page-top-margin {
  margin-top: 81px;
  margin-bottom: 30px;
}
.faq-search-bar-height {
  height: 80px;
  max-width: 821px;
  padding: 6px 10px 0px 10px;
  margin: 0px auto;
}
.faq-search-bar-button-padding {
  padding: 13px;
}
.faq-card-row-top-space {
  margin-top: 55px;
}
.collapse-button-faq-50 {
  height: 50px;
}
.customize-bottom-cards-faq-768 {
  width: 100%;
  margin-left: 0px;
}
/* =============safety============== */
.safety-top-sapce {
  margin-top: 73px;
}
.safety-containerr-inner-padding {
  padding: 90px 103px 103px 103px;
}
.safety-protocol-top-space {
  margin-top: 178px;
}

.padding-for-safety-icons {
  padding: 0px 50px 54px 50px;
}
.padding-forboth-customer {
  padding: 16px 10px 0px 9px;
}
.covid-safety-top-space {
  margin: 100px 0px 32px 0px;
}
.stylist-with-carry-second-image {
  width: 318px;
  height: 332px;
  border-radius: 30px;
  position: relative;
  top: 40px;
}
.stylist-with-carry-third-image {
  width: 160px;
  height: 167px;
  border-radius: 30px;
  margin-top: 140px;
}
.safety-and-constantly-paddinf {
  padding: 43px 0px 100px 87px;
}
.safety-photos-top-space {
  margin-top: 35px;
}
.safety-helping-image {
  width: 107px;
  height: 109px;
  position: absolute;
  top: 0px;
  right: 59px;
  z-index: 99;
}
.hhelping-image-div {
  height: 373px;
}
.building-a-safer-padding {
  padding: 0px 20px 100px 20px;
}
.building-a-safer-text-padding {
  padding: 0px 100px 0px 20px;
}
.customer-safety-image {
  width: 194px;
  height: 172.31px;
}
.customer-safety-below-text {
  padding: 32px 0px 22px 0px;
}
.customer-safety-bottom-button-padding {
  padding: 22px 0px 0px 0px;
}
/* ======customer-safety============= */
.covid-19-door-top-div-space {
  margin-top: 174px;
}
.covid-19-door-bg {
  height: 120px;
  width: 120px;
  margin: 0px auto;
  background-color: white;
  box-shadow: 0px 50px 40px rgba(0, 0, 0, 0.02);
  border-radius: 43.2778px;
}
.covid-19-icon-top-space {
  margin-top: 40px;
}
.safetyis-our-key-priority-right-padding {
  padding-left: 87px;
}
.both-the-safetytop-space {
  margin-top: 29px;
}
.width-inherit {
  width: 100%;
  height: auto;
}
.safety-is-our-key-priority-top-space {
  margin-top: 204px;
  margin-bottom: 100px;
}
.reviews-and-text-space {
  padding: 24px 0px 16px 0px;
}
.customer-safety-bottom-space-div {
  padding-bottom: 205px;
}
/* ===========about us =================== */
.about-us-top-space {
  margin: 55px auto 0px auto !important;
}
.about-us-lorm-ispum-padding {
  padding: 14px 220px 74px 220px;
}
.about-us-top-image {
  width: 821px;
  height: 579px;
}
.with-lots-of {
  padding: 20px 68px 75px 10px;
}
.work-around-image-1 {
  width: 100%;
  height: auto;
  border-radius: 27.7778px;
}
.work-around-image-2 {
  width: 100%;
  height: auto;
  border-radius: 27.7778px;
}
.dedicated-row-top-space {
  padding: 120px 0px 0px 0px;
}
.work-around-main-div-top-and-bottom-space {
  margin: 128px 0px 104px 0px;
}
.with-lots-of-unique-padding {
  padding: 25px 260px 51px 260px;
}
.joe-bridges-photo {
  width: 267px;
  height: 356px;
}
.joe-bridges-text-padding {
  padding: 25px 0px 4px 0px;
}
.founder-text-ottom-space {
  padding-bottom: 60px;
}
.apply-now-link-top-space {
  margin-top: 23px;
}
.intrsted-to-join-top-space {
  margin-top: 50%;
}
.have-any-que-main-div-space {
  margin: 78px 0px 132px 0px;
}
.dont-be-stranger-padding {
  padding: 27px 90px 49px 90px;
}
/* ===============contact us============ */
.here-to-help-you {
  margin-top: 100px;
  padding: 0px 50px 0px 50px;
}
.here-to-help-you-lorem-text {
  margin-top: 19px;
  opacity: 0.7;
}
.contact-us-card-position {
  position: relative;
  bottom: 180px;
}
.cntact-uform-position {
  position: relative;
  bottom: 100px;
}
.form-checkox-padding {
  padding: 0px 20px 0px 40px;
}
.contact-us-container-new {
  max-width: 722px;
  margin: 0px auto;
}
.leave-us-lorem-text-form {
  margin: 19px 0px 94px 0px;
}
.input-fields-form-padding {
  padding: 0px 20px;
}
.dontbe-stranger-padding {
  padding: 5% 0px 0px 0px;
}
.about-us-left-image {
  background-image: url(/static/media/about_icons_2.dfce7af7.png);
  background-position: left center;
  background-repeat: no-repeat;
  top: 15px;

  /* width: 400px;
    height: 400px; */
  /* position: relative; */
  /* top: 20px; */
  /* overflow-y: hidden; */
}
.about-us-right-image {
  background-image: url(/static/media/about_icons_1.4bfaf98d.png);
  /* width: 400px;
  height: 400px; */
  overflow-y: hidden;
  background-repeat: no-repeat;
  background-position: right center;
  top: 50px;
}
.height-foraboust-us-icon-1 {
  height: 231px;
  width: 220px;
  position: relative;
  right: 31px;
}
.height-foraboust-us-icon-2 {
  height: 231px;
  width: 220px;
  position: relative;
  left: 30px;
  top: 25px;
}
.dont-be-last-margin-bottom {
  margin-bottom: 46px;
}
.login-container {
  max-width: 600px;
  margin: 0px auto;
}
/* ==============side-bar============= */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: rgba(0, 0, 0, 0.9);
}
.sidenav a:active {
  color: rgba(0, 0, 0, 0.9) !important;
}

.sidenav .nav-link.active {
  color: rgba(0, 0, 0, 0.9) !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

/* side-bar */
.sidenav {
  /* height: fit-content; */
  width: 0;
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  padding-bottom: 100px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 35px;
  font-size: 36px;
  margin-left: 30px;
}
.burger-menue {
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.margin-top-60px-for-row-stylist {
  margin-top: 50px;
  padding: 0px 20px;
}
.people-card-photo-padding {
  padding: 24px 0px 24px 0px !important;
}

.text-bold-grey {
  color: #212529 !important;
}

.our-feature-section-margin-adjust {
  margin: 0px auto !important;
}
.COVID-19-Door-to-Door-Safety-content-padding {
  padding: 0px 0px 92px 0px;
}

/* ======modal========== */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 15%; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 50px;
  border: 1px solid #888;
  width: 30%;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

/* 
#toast-container>div {
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 3px;  
} */
.toast {
  top: 20% !important;
}
.stylist-checkbox:focus {
  box-shadow: none !important;
}
.modal-thankyou-text {
  font-size: 22px;
  line-height: 36px;
  color: #6b6b6b;
  opacity: 0.7;
}

.dotted-relative-image {
  position: relative;
  left: 30px;
}

.home-dotted-line-bottom {
  bottom: 53%;
}
.navbar-brand-height {
  height: 24px;
}
.navbar-brand-height-for-footer {
  height: 28px;
}
.download-now-button-width {
  width: 70%;
}
.become-astylist-button-padding {
  padding: 6.9px;
}
.play-store-on-mobile {
  width: auto;
  margin-left: 34px;
  margin-top: 8px;
  margin-bottom: 8px;
}
.padding-for-home-faq {
  padding: 0px 30px 0px 20px;
}
.stylist-faq-padding {
  padding: 0px 40px 0px 40px;
}
.join-content-margin {
  margin: 16px auto 0px auto !important;
}

@media (max-width: 992px) {
  .we-are-incredible-padding{
    padding: 0;
  }
  .accordion .col-lg-11 {
    max-width: none !important;
  }
}

.home-work-desc.d-block.text-center.justify-content-center.feature.if-your-padding {
  padding-top: 16px;
}

@media (min-width: 992px){
.faq-container .col-lg-11 {
    max-width: none !important;
}
.stylist-faq-container .col-lg-11 {
  max-width: none !important;
}
}

.safety-footer-heading {
  font-weight: 900;
  font-size: 2.2rem;
  /* width: 100%; */
}

.safety-footer-text {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
  padding: 22px 0px;
}

.safety-image-text {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
  height: 105px;
}
.safety-image-text1 {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
}

.safety-footer-image1 {
  height: 120px;
  width: 120px;
}

.safety-image1-heading {
  font-size: 29px;
  font-weight: 800;
  margin-right: -14px;
}

.safety-footer-btn1 {
  border: 1px solid black;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 800;
  background-color: transparent;
}

.safety-joury-container {
  padding-top: 5%;
}

.customer-heading {
  font-weight: 900;
  font-size: 48px;
}

.customer-heading-text {
  font-size: 16px;
  line-height: 36px;
  color: #6b6b6b;
}

.customer-icon-heading-text {
  font-size: 16px;
  line-height: 28px;
  color: #6b6b6b;
}

.customer-icon {
  height: 60px;
  width: 60px;
}

.customer-icon-heading {
  font-size: 24px;
  font-weight: 800;
  font-style: SemiBold;
  line-height: 36px;
}

.customer-width {
  width: 65%;
}

.customer-box-common {
  background-color: white;
  width: 101.79px;
  height: 101.79px;
  box-shadow: 0px 20px 37.3458px rgba(0, 0, 0, 0.04);
  border-radius: 40.4062px;
  position: relative;
}

.customer-way-text {
  font-size: 22px;
  line-height: 35px;
  font-weight: 700;
}
.customer-desc-way {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  opacity: 0.7;
  color: #6b6b6b;
}

.safety-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 600;
}
.safety-desc-text {
  font-size: 28px;
  line-height: 43.5px;
  font-weight: 600;
  color: #000000;
  padding-right: 263px ;
}
.safety-image {
  width: 100%;
  margin-top: 87px;
  border-radius: 20px;
  
}
.safety-image-1 {
  width: 45vw;
  border-radius: 20px;
  position: relative;
}
.safety-image-2 {
  position: absolute;
  top: -46px;
  left: 82%;
}
.safety-image-3-div{
    left: 60%;
    top: 13%;
}

.safety-image-3 {
  width: 300%;
}
.safety-common-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.5px;
  color: #161C2D;
  /* width: 153%; */
}

.safety-helping-container {
  margin-top: 40px;
}

@media only screen and (max-width: 992px) {
  .safety-image-2 {
    z-index: -1;
  }

  .customer-heading {
    font-weight: 900;
    font-size: 32px;
    padding: 0px 45px;
  }
}

@media only screen and (min-width: 992px) {
  .safety-image {
    border-radius: 20px;
  }
  .safety-image-1 {
    border-radius: 20px;
  }
  .safety-image-2 {
    position: absolute;
    top: -45px;
    left: 140%;
  }
  .safety-image-3 {
    border-radius: 20px;
  }
  .safety-desc-text {
    font-size: 31px;
  }
}

@media only screen and (max-width: 1439px) {
  .home-work-icon-title {
    font-size: 18px !important;
  }
}
.safety-heding-bottom-space{
  margin-bottom: 16px;
}
.customer-safety-heading-bottom-space{
  margin-bottom: 46px;
}
.signup-crreate-account {
  text-align: center;
  font-weight: 600;
  font-size: 26px;
  line-height: 39px;
}
.signup-desc {
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  color: #6b6b6b;
  letter-spacing: 0.5px;
}

.signup-first-name,
.signup-Last-name,
.signup-email,
.signup-password,
.signup-Phone {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}
.signup-first-input,
.signup-Last-input {
  /* width: 100%; */
  height: 60px;
  border: 0.5px solid #d6d6d6;
  outline: none;
  border-radius: 5px;
}
.signup-first-input:focus,
.signup-Last-input:focus {
  border: 0.5px solid #000;
}

.signup-email-input,
.signup-password-input,
.signup-Phone-input {
  height: 60px;
  border: 0.5px solid #d6d6d6;
  outline: none;
  border-radius: 5px;
}

.signup-email-input:focus,
.signup-password-input:focus,
.signup-Phone-input:focus {
  border: 0.5px solid #000;
}

::-webkit-input-placeholder {
  font-size: 14px;
}

:-ms-input-placeholder {
  font-size: 14px;
}

::placeholder {
  font-size: 14px;
}
.signup-btn {
  background-color: black;
  border-radius: 50px;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 12px;
  line-height: 18px;
}

.about-font {
  font-size: 50px;
  font-weight: 600;
  text-align: center;
  line-height: 60px;
}

.about-desc {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #161c2d;
}

.about-meet-descs {
  font-size: 18px;
  line-height: 32px;
  color: #161c2d;
  opacity: .7 !important;
  /* max-width: 50%; */
}

.about-last-container{
  background-color: #ffffff;
}

.about-box-work {
  width: 100%;
  background-color: #edf1f5;
  border-radius: 50px;
  padding: 105px 114px 106px 110px;
}
.about-work-text {
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  color: #000000;
  padding: 0px 7px !important;
}
.about-unique-text {
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #161c2d;
}
.footer-image {
  border-radius: 40px;
}
.about-dedicated-text {
  font-size: 21px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #161c2d;
}
.about-dedicated-text-icon{
  position: absolute;
  width: 70%;
  top: 20px;
  left: 0;
}
.about-dedicated-desc {
  font-size: 15px;
  font-weight: 400;
  line-height: 27px;
  opacity: 0.7;
  color: #161c2d;
}
.about-meet-text {
  font-size: 48px;
  font-weight: 600;
  text-align: center;
  line-height: 58px;
  letter-spacing: 0.5px;
}
.about-image {
  border-radius: 10px;
}
.about-text-1 {
  font-size: 21px;
  line-height: 32px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.about-desc-1 {
  font-size: 17px;
  line-height: 29px;
  font-weight: 400;
  color: #161c2d;
}
.about-text-team {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  color: #161c2d;
}
.about-desc-team {
  font-size: 21px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 0.5px;
  color: #000000;
}
.about-any-text {
  font-size: 20px;
  line-height: 33px;
  font-weight: 400;
  color: #64626a;
}
.about-any-desc-text {
  text-align: center;
  font-size: 46px;
  line-height: 60px;
  color: #000000;
  font-weight: 600;
}
.about-contact-btn {
  width: 160px;
  height: 48px;
  font-size: 14px;
  line-height: 18px;
  background: #000000;
  color: #ffffff;
  border-radius: 50px;
  outline: none;
  border: none;
}

.about-apply {
  cursor: pointer;
}

.about-tagline-img-2 {
  right: 0;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  z-index: 1;
}

.about-last-sec {
  left: 0;
}

.about-last-sec-2 {
  right: 0;
}

.about-line {
  right: 0;
  -webkit-transform: translate(0%, 20%);
          transform: translate(0%, 20%);
  z-index: 1;
  width: 300px;
}

.footer-img-size {
  width: 184px;
  height: 58px;
}

.footer-bg {
  background-color: #000;
}

.about-helping-main-content {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media only screen and (min-width: 992px) {

  .about-dedicated-text {
    font-size: 18px;
  }

  .about-bg-imgs {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .about-box-work {
    width: 100%;
    background-color: #edf1f5;
    border-radius: 50px;
  }

  .footer-image {
    width: 100%;
  }
}

