.safety-footer-heading {
  font-weight: 900;
  font-size: 2.2rem;
  /* width: 100%; */
}

.safety-footer-text {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
  padding: 22px 0px;
}

.safety-image-text {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
  height: 105px;
}
.safety-image-text1 {
  color: #6b6b6b;
  font-size: 0.8rem;
  line-height: 28px;
}

.safety-footer-image1 {
  height: 120px;
  width: 120px;
}

.safety-image1-heading {
  font-size: 29px;
  font-weight: 800;
  margin-right: -14px;
}

.safety-footer-btn1 {
  border: 1px solid black;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 800;
  background-color: transparent;
}

.safety-joury-container {
  padding-top: 5%;
}

.customer-heading {
  font-weight: 900;
  font-size: 48px;
}

.customer-heading-text {
  font-size: 16px;
  line-height: 36px;
  color: #6b6b6b;
}

.customer-icon-heading-text {
  font-size: 16px;
  line-height: 28px;
  color: #6b6b6b;
}

.customer-icon {
  height: 60px;
  width: 60px;
}

.customer-icon-heading {
  font-size: 24px;
  font-weight: 800;
  font-style: SemiBold;
  line-height: 36px;
}

.customer-width {
  width: 65%;
}

.customer-box-common {
  background-color: white;
  width: 101.79px;
  height: 101.79px;
  box-shadow: 0px 20px 37.3458px rgba(0, 0, 0, 0.04);
  border-radius: 40.4062px;
  position: relative;
}

.customer-way-text {
  font-size: 22px;
  line-height: 35px;
  font-weight: 700;
}
.customer-desc-way {
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  opacity: 0.7;
  color: #6b6b6b;
}

.safety-text {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  font-weight: 600;
}
.safety-desc-text {
  font-size: 28px;
  line-height: 43.5px;
  font-weight: 600;
  color: #000000;
  padding-right: 263px ;
}
.safety-image {
  width: 100%;
  margin-top: 87px;
  border-radius: 20px;
  
}
.safety-image-1 {
  width: 45vw;
  border-radius: 20px;
  position: relative;
}
.safety-image-2 {
  position: absolute;
  top: -46px;
  left: 82%;
}
.safety-image-3-div{
    left: 60%;
    top: 13%;
}

.safety-image-3 {
  width: 300%;
}
.safety-common-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0.5px;
  color: #161C2D;
  /* width: 153%; */
}

.safety-helping-container {
  margin-top: 40px;
}

@media only screen and (max-width: 992px) {
  .safety-image-2 {
    z-index: -1;
  }

  .customer-heading {
    font-weight: 900;
    font-size: 32px;
    padding: 0px 45px;
  }
}

@media only screen and (min-width: 992px) {
  .safety-image {
    border-radius: 20px;
  }
  .safety-image-1 {
    border-radius: 20px;
  }
  .safety-image-2 {
    position: absolute;
    top: -45px;
    left: 140%;
  }
  .safety-image-3 {
    border-radius: 20px;
  }
  .safety-desc-text {
    font-size: 31px;
  }
}

@media only screen and (max-width: 1439px) {
  .home-work-icon-title {
    font-size: 18px !important;
  }
}
.safety-heding-bottom-space{
  margin-bottom: 16px;
}
.customer-safety-heading-bottom-space{
  margin-bottom: 46px;
}